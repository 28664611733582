import {
  INVOICE_CUSTOMER_SELECT_PATH,
  SENDED_STATUS_CUSTOMER_SELECT_PATH,
} from 'const/admin/AdminPath'
import { Link } from 'react-router-dom'
import { useAuthUser } from 'routes/AuthUserContext'
import AdminHeader from './AdminHeader'

const AdminHome: React.FC = () => {
  const authUser = useAuthUser()

  return (
    <div>
      <AdminHeader
        title={`管理者メニュー - ${authUser ? authUser.customerName : ''}`}
      />
      <ul>
        <li>
          <Link to="/admin/editCustomer">顧客管理</Link>
        </li>
        <li>
          <Link to={INVOICE_CUSTOMER_SELECT_PATH}>請求情報</Link>
        </li>
        <li>
          <Link to={SENDED_STATUS_CUSTOMER_SELECT_PATH}>配信状況</Link>
        </li>
      </ul>
    </div>
  )
}

export default AdminHome
