import { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Button,
  Alert,
  Container,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { flyerTypes, locationTypes, viewCountData } from 'types/flyer'
import ConnectAxios from 'utils/connectAxios'
import { options } from 'types/tag'
import CustomQuill from 'components/common/CustomQuill'
import ModalMap from 'components/common/ModalMap'
import { viewCountTypes } from 'types/viewCount'
import { useAuthUser } from 'routes/AuthUserContext'
import CustomerHeader from '../CustomerHeader'
import { zoomCalc } from 'utils/zoomCalc'
import { useDebouncedCallback } from 'use-debounce'
import ConfirmDialog from 'components/common/ConfirmDialog'

export const location = {
  radius: 500.0,
  lat: 36.643808,
  lng: 138.188202,
}

const EditFlyer: React.FC = () => {
  const authUser = useAuthUser()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const [toDate, setToDate] = useState<Date>(new Date())
  const [fromDate, setFromDate] = useState<Date>(new Date())
  const [isInitial, setIsInitial] = useState(false)
  const [images, setImages] = useState([])
  const [zoom, setZoom] = useState<number | undefined>(15)

  const [isRadiusFormChanged, setIsRadiusFormChanged] = useState(false)

  const [radiusInputForm, setRadiusInputForm] = useState<string>(
    String(location.radius),
  )
  const [isInvalidRadius, setIsInvalidRadius] = useState<boolean>(false)

  const [locationInfo, setLocationInfo] = useState<locationTypes>(location)

  const [isOperator, setIsOperator] = useState<boolean>(false)

  const flyer = {
    title: '',
    content: '',
    tag: 1,
    isUnlimitedRange: false,
  }
  const [createFlyer, setCreateFlyer] = useState<flyerTypes>(flyer)
  const [createFlyerError, setCreateFlyerError] = useState({
    title: [],
    content: [],
    detail: [],
  })

  const initialCenter = {
    lat: 36.643808,
    lng: 138.188202,
  }

  const [center, setCenter] = useState(initialCenter)
  const [imageIds, setImageIds] = useState<string[]>([])

  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    setCenter({ lat: locationInfo.lat, lng: locationInfo.lng })
    setIsRadiusFormChanged(false)
  }
  const handleShow = () => {
    setShow(true)
    setModalNewOpen(true)
    setLocationInfo({ ...locationInfo })
  }

  const [modalNewOpen, setModalNewOpen] = useState(false)

  const [initialLocationInfo, setInitialLocationInfo] = useState<locationTypes>(
    {
      lat: 0,
      lng: 0,
      radius: 0,
    },
  )

  useEffect(() => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    if (id === undefined) {
      axiosInstance
        ?.get(`/api-admin/customer-get-only/${authUser?.customerId}/`)
        .then((res) => {
          setCenter({
            lat: res.data.lat,
            lng: res.data.lng,
          })
          setLocationInfo({
            ...locationInfo,
            lat: res.data.lat,
            lng: res.data.lng,
          })
          setInitialLocationInfo({
            ...initialLocationInfo,
            lat: res.data.lat,
            lng: res.data.lng,
          })

          setIsOperator(res.data.isOperator)
        })
        .catch((e) => {
          console.log(e.response)
        })
      return
    }
    axiosInstance
      ?.get(`api-admin/create-flyer/${id}/`)
      .then((res) => {
        setIsInitial(true)
        setCreateFlyer({
          ...createFlyer,
          title: res.data.title,
          content: res.data.content,
          tag: res.data.tag,
          isUnlimitedRange: res.data.isUnlimitedRange,
        })

        setIsOperator(res.data.isOperator)

        setCenter({
          lat: res.data.lat,
          lng: res.data.lng,
        })
        setLocationInfo({
          ...locationInfo,
          lat: res.data.lat,
          lng: res.data.lng,
          radius: res.data.radius,
        })
        setInitialLocationInfo({
          lat: res.data.lat,
          lng: res.data.lng,
          radius: res.data.radius,
        })
        setZoom(zoomCalc(res.data.radius))
        setRadiusInputForm(res.data.radius)
        const defaultFromDate = new Date(res.data.fromDate)
        const defaultToDate = new Date(res.data.toDate)
        setFromDate(defaultFromDate)
        setToDate(defaultToDate)
      })
      .then(() => {
        setIsInitial(false)
      })
      .catch((e) => {
        console.log(e.request)
        console.log(e.response)
        console.log(e.message)
      })
  }, [])

  const newFlyer = (
    createFlyer: flyerTypes,
    locationInfo: locationTypes,
    fromDate: Date,
    toDate: Date,
  ): void => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    const data = { ...createFlyer, ...locationInfo, fromDate, toDate, images }
    if (id) {
      axiosInstance
        ?.put(`api-admin/create-flyer/${id}/`, data)
        .then(() => {
          history.push('/customer/flyer')
        })
        .catch((e) => {
          e.response.data.title ||
          e.response.data.content ||
          e.response.data.detail
            ? setCreateFlyerError(e.response.data)
            : alert('不正なリクエストです')
          window.scrollTo(0, 0)
        })
    } else {
      axiosInstance
        ?.post('api-admin/create-flyer/', data)
        .then(() => {
          history.push('/customer/flyer')
        })
        .catch((e) => {
          setCreateFlyerError(e.response.data)
          window.scrollTo(0, 0)
        })
    }
  }

  const initialCount = {
    yesterday: {
      count: 0,
      date: '',
    },
    twoDaysAgo: {
      count: 0,
      date: '',
    },
    threeDaysAgo: {
      count: 0,
      date: '',
    },
    fourDaysAgo: {
      count: 0,
      date: '',
    },
    fiveDaysAgo: {
      count: 0,
      date: '',
    },
    sixDaysAgo: {
      count: 0,
      date: '',
    },
    sevenDaysAgo: {
      count: 0,
      date: '',
    },
  }
  console.log(initialCount)

  const [viewCount, setViewCount] = useState<viewCountTypes>()

  const getViewCount = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    const data: viewCountData = {
      lat: locationInfo.lat,
      lng: locationInfo.lng,
      radius: locationInfo.radius,
      from_date: fromDate,
      to_date: toDate,
    }
    axiosInstance
      ?.post('api-admin/flyer/view_count/', data)
      .then((res) => {
        console.log(res.data)
        setViewCount(res.data)
      })
      .catch((e) => {
        console.log(e.response)
      })
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCreateFlyer({ ...createFlyer, title: e.target.value })
  }

  // https://github.com/zenoamaro/react-quill/issues/688
  const debounced = useDebouncedCallback((content: string) => {
    setCreateFlyer({ ...createFlyer, content: content })
  }, 500)
  const handleSetContent = (content: string) => {
    debounced(content)
  }

  const handleSetImageId = useCallback(
    (imageId: string) => {
      imageIds.push(imageId)
      setImageIds(imageIds)
    },
    [imageIds],
  )

  const handleCheckboxClick = (_e) => {
    const isUnlimitedRange = createFlyer.isUnlimitedRange
    setCreateFlyer({ ...createFlyer, isUnlimitedRange: !isUnlimitedRange })
  }

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const onSubmitCancel = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    const dateNow = new Date()
    const data = {
      ...createFlyer,
      ...locationInfo,
      fromDate,
      toDate: dateNow,
      images,
    }
    axiosInstance
      ?.put(`api-admin/create-flyer/${id}/`, data)
      .then(() => {
        history.push('/customer/flyer')
      })
      .catch((e) => {
        e.response.data.title ||
        e.response.data.content ||
        e.response.data.detail
          ? setCreateFlyerError(e.response.data)
          : alert('不正なリクエストです')
        window.scrollTo(0, 0)
      })
  }

  const mapStyle = {
    width: '100%',
    height: '50vh',
  }

  const tableStyle = {
    width: '100%',
    height: '500px',
  }

  const formStyle = {
    outline: 'none',
    border: 'solid 0.1rem red',
    borderRadius: '3px',
  }

  // exp. http://localhost:3000/customer/1/editFlyer
  return (
    <>
      <CustomerHeader
        title={`情報管理 - 編集画面 ： ${
          authUser ? authUser.customerName : ''
        }`}
      />
      <Container className="mt-5">
        <h2>編集画面</h2>
        <hr />
        <Row>
          <Col md="6" className="mb-3">
            <Button variant="primary" onClick={handleShow}>
              位置情報を設定
            </Button>
            {id && (
              <Button
                variant="danger"
                onClick={() => setIsConfirmModalOpen(true)}
                style={{ marginLeft: '1rem' }}
              >
                配信中止
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <ModalMap
            style={mapStyle}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            center={center}
            setCenter={setCenter}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            isInitial={isInitial}
            setModalNewOpen={setModalNewOpen}
            modalNewOpen={modalNewOpen}
            initialLocationInfo={initialLocationInfo}
            isRadiusFormChanged={isRadiusFormChanged}
            setIsRadiusFormChanged={setIsRadiusFormChanged}
            zoom={zoom}
            setZoom={setZoom}
            setRadiusInputForm={setRadiusInputForm}
          />
          <Col md="10">
            <div id="flyerId"></div>
            <Table
              striped
              bordered
              hover
              className="table"
              id="circleInfo"
              style={tableStyle}
              responsive
            >
              <tbody>
                <tr>
                  <td>タイトル</td>
                  <td colSpan={7}>
                    {!createFlyerError.title ||
                      createFlyerError.title.map((e) => {
                        return (
                          <Alert key={e} variant="danger">
                            {e}
                          </Alert>
                        )
                      })}
                    <input
                      type="text"
                      id="info-title"
                      value={createFlyer.title}
                      onChange={handleTitleChange}
                      style={{ width: 600 }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>開始日時</td>
                  <td colSpan={7}>
                    {id ? (
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip id="notify">
                            作成した情報の開始日時を変更することはできません
                          </Tooltip>
                        }
                      >
                        <span>
                          <DatePicker
                            selected={fromDate}
                            dateFormat="yyyy/MM/dd HH:mm"
                            onChange={(date: Date) => {
                              setFromDate(date)
                            }}
                            timeFormat="HH:mm"
                            showTimeSelect
                            timeIntervals={60}
                            disabled
                          />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <DatePicker
                        selected={fromDate}
                        dateFormat="yyyy/MM/dd HH:mm"
                        onChange={(date: Date) => {
                          setFromDate(date)
                        }}
                        timeFormat="HH:mm"
                        showTimeSelect
                        timeIntervals={60}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>終了日時</td>
                  <td colSpan={7}>
                    {!createFlyerError.detail ||
                      createFlyerError.detail.map((e) => {
                        return (
                          <Alert key={e} variant="danger">
                            {e}
                          </Alert>
                        )
                      })}
                    <DatePicker
                      selected={toDate}
                      dateFormat="yyyy/MM/dd HH:mm"
                      onChange={(date: Date) => {
                        setToDate(date)
                      }}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeIntervals={60}
                    />
                  </td>
                </tr>
                <tr>
                  <td>タグ</td>
                  <td colSpan={7}>
                    <select
                      value={createFlyer.tag}
                      onChange={(e) => {
                        setCreateFlyer({
                          ...createFlyer,
                          tag: Number(e.target.value),
                        })
                      }}
                    >
                      {options.map((option) => (
                        <option value={option.value} key={option.key}>
                          {option.key}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>内容</td>
                  <td colSpan={7}>
                    {!createFlyerError.content ||
                      createFlyerError.content.map((e) => {
                        return (
                          <Alert key={e} variant="danger">
                            {e}
                          </Alert>
                        )
                      })}
                    {/* <div id="toolbar-container"></div> */}
                    <CustomQuill
                      staffId={authUser?.userId}
                      content={createFlyer.content}
                      handleSetImageId={handleSetImageId}
                      handleSetContent={handleSetContent}
                      isInitial={isInitial}
                      setImage={setImages}
                    />
                  </td>
                </tr>
                <tr>
                  <td>半径</td>
                  <td colSpan={7}>
                    <div id="info-radius">
                      <Button
                        variant="primary"
                        className="mb-3"
                        onClick={handleShow}
                      >
                        位置情報を設定
                      </Button>
                      <div
                        className="custom-control custom-checkbox"
                        style={{ display: isOperator ? '' : 'none' }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="defaultUnchecked"
                          checked={createFlyer.isUnlimitedRange}
                          onChange={handleCheckboxClick}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultUnchecked"
                        >
                          範囲無制限
                        </label>
                      </div>
                      <br />
                      <input
                        type="text"
                        value={radiusInputForm}
                        onChange={(e) => {
                          setRadiusInputForm(e.target.value)
                          if (
                            e.target.value === '' ||
                            e.target.value.match(/[^0-9]+/i)
                          ) {
                            setIsInvalidRadius(true)
                          } else {
                            setIsInvalidRadius(false)
                          }
                        }}
                        onBlur={() => {
                          setIsInvalidRadius(false)
                          if (
                            radiusInputForm === '' ||
                            radiusInputForm.match(/[^0-9]+/i)
                          ) {
                            setRadiusInputForm(String(locationInfo.radius))
                            return
                          }
                          setLocationInfo({
                            ...locationInfo,
                            radius: Number(radiusInputForm),
                          })
                          setZoom(zoomCalc(Number(radiusInputForm)))
                          setIsRadiusFormChanged(true)
                        }}
                        style={
                          isInvalidRadius
                            ? formStyle
                            : { border: 'solid 1px gray' }
                        }
                      />
                      m
                      <br />
                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => getViewCount()}
                      >
                        閲覧数更新
                      </Button>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td rowSpan={2}>閲覧数</td>
                  <td align="right">
                    <div id="info-viewes-day-00">
                      {viewCount && viewCount.yesterday.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-01">
                      {viewCount && viewCount.twoDaysAgo.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-02">
                      {viewCount && viewCount.threeDaysAgo.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-03">
                      {viewCount && viewCount.fourDaysAgo.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-04">
                      {viewCount && viewCount.fiveDaysAgo.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-05">
                      {viewCount && viewCount.sixDaysAgo.date}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-day-06">
                      {viewCount && viewCount.sevenDaysAgo.date}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td align="right">
                    <div id="info-viewes-cnt-00">
                      {viewCount && viewCount.yesterday.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-01">
                      {viewCount && viewCount.twoDaysAgo.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-02">
                      {viewCount && viewCount.threeDaysAgo.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-03">
                      {viewCount && viewCount.fourDaysAgo.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-04">
                      {viewCount && viewCount.fiveDaysAgo.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-05">
                      {viewCount && viewCount.sixDaysAgo.count}
                    </div>
                  </td>
                  <td align="right">
                    <div id="info-viewes-cnt-06">
                      {viewCount && viewCount.sevenDaysAgo.count}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="hidden" id="info-id" value="" />
                    <input type="hidden" id="info-customerName" value="" />
                  </td>
                  <td colSpan={7}>
                    <Button
                      onClick={() =>
                        newFlyer(createFlyer, locationInfo, fromDate, toDate)
                      }
                    >
                      登録する
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to={'/customer/flyer'} style={{ marginBottom: '1rem' }}>
                登録せずに戻る
              </Link>
              <ConfirmDialog
                title="配信中止"
                onSubmit={() => onSubmitCancel()}
                onClose={() => setIsConfirmModalOpen(false)}
                submitText="配信中止する"
                isOpen={isConfirmModalOpen}
              >
                <h4>この情報を配信中止してもよろしいですか？</h4>
                <br />
                <p>
                  配信を再開したい場合は情報編集画面から終了日時を編集してください
                </p>
              </ConfirmDialog>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EditFlyer
