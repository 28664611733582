import { useRef, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api'
import { mapTypes } from 'types/map'
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap'
import Geocode from 'react-geocode'
import { zoomCalc } from 'utils/zoomCalc'
import { location } from 'components/customer/editFlyer/EditFlyer'

Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOAPI}`)

const options = {
  strokeColor: '#808080',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#808080',
  fillOpacity: 0.35,
  clickable: true,
  draggable: true,
  editable: true,
  visible: true,
  zIndex: 1,
}

const ModalMap: React.FC<mapTypes> = (props: mapTypes) => {
  const { id } = useParams<{ id: string }>()

  const {
    style,
    locationInfo,
    setLocationInfo,
    center,
    setCenter,
    show,
    handleClose,
    isInitial,
    setModalNewOpen,
    modalNewOpen,
    initialLocationInfo,
    isRadiusFormChanged,
    setIsRadiusFormChanged,
    zoom,
    setZoom,
    setRadiusInputForm,
  } = props

  const mapRef = useRef<any | null>(null)
  const onCircleLoad = useCallback((circle) => {
    mapRef.current = circle
    if (isInitial === false) return
    setLocationInfo({ ...locationInfo })
    setCenter({ ...center })
  }, [])

  function handleRadiusChanged() {
    const newRadius = mapRef.current?.getRadius()
    if (isRadiusFormChanged) {
      setIsRadiusFormChanged(false)
      return
    }
    if (typeof newRadius === 'undefined') return
    const integerRadius = Math.floor(newRadius)
    setZoom(zoomCalc(integerRadius))
    setLocationInfo({ ...locationInfo, radius: integerRadius })
    setRadiusInputForm(String(integerRadius))
  }

  function handleCenterChanged() {
    if (modalNewOpen === true) {
      setModalNewOpen(false)
      return
    }
    const newCenter = mapRef.current?.getCenter()
    setLocationInfo({
      ...locationInfo,
      lat: newCenter?.lat(),
      lng: newCenter?.lng(),
    })
  }

  const backLocationInfo = () => {
    setCenter({
      lat: initialLocationInfo?.lat,
      lng: initialLocationInfo?.lng,
    })
    if (id) {
      setLocationInfo(initialLocationInfo)
      setRadiusInputForm(String(initialLocationInfo?.radius))
    } else {
      setLocationInfo({ ...initialLocationInfo, radius: location.radius })
      setRadiusInputForm(String(location.radius))
    }
  }

  const [searchValue, setSearchValue] = useState('')

  const searchLocationInfo = () => {
    Geocode.fromAddress(searchValue).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        console.log(lat, lng)
        setLocationInfo({ ...locationInfo, lat: lat, lng: lng })
        setCenter({ lat: lat, lng: lng })
      },
      (error) => {
        console.error(error)
      },
    )
    setSearchValue('')
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>位置情報を入力してください</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="住所を入力してください"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            variant="primary"
            className="ml-3"
            id="button-addon2"
            onClick={searchLocationInfo}
          >
            検索する
          </Button>
        </InputGroup>
        <LoadScript
          googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
        >
          <GoogleMap
            id="map"
            mapContainerStyle={style}
            center={center}
            zoom={zoom}
          >
            <Circle
              onLoad={onCircleLoad}
              radius={locationInfo.radius}
              center={center}
              options={options}
              onRadiusChanged={handleRadiusChanged}
              onCenterChanged={handleCenterChanged}
            />
          </GoogleMap>
        </LoadScript>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          閉じる
        </Button>
        <Button variant="secondary" onClick={backLocationInfo}>
          元に戻す
        </Button>
        <Button variant="primary" onClick={handleClose}>
          追加する
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalMap
