import { useEffect, useState, useCallback } from 'react'
import CustomerSelectList from './CustomerSelectList'
import Pagination from 'utils/Pagination'
import ConnectAxios from 'utils/connectAxios'
import useAxios from 'hocks/axios'
import { AxiosInstance } from 'axios'
import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import AdminHeader from 'components/admin/AdminHeader'
import { Link, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import CustomerSearchWindow from './CustomerSearchWindow'
import ChangeCategoryRadio from './ChangeCategoryRadio'
import {
  DEFAULT_SEATCH_TYPE,
  DEFAULT_SEATCH_WORD,
  DEFAULT_PAGE_INDEX,
} from 'const/admin/SeacrhCustomer'
import { searchType } from 'types/customers'
import { isSearchType } from 'utils/searchCondition'

const emptyStringToDefaultSearchWordConverter = (value: string): string => {
  if (value === '') {
    return DEFAULT_SEATCH_WORD
  }
  return value
}

const CustomerSelect: React.FC = () => {
  const { title, searchType, searchWord, pageIndex } =
    useParams<{
      title: string
      searchType: string
      searchWord: string
      pageIndex: string
    }>()
  const page = Number(pageIndex) ?? DEFAULT_PAGE_INDEX

  const [customers, setCustomers] = useState([])
  const [customerSize, setCustomerSize] = useState(1)
  const [customerPerPage, setCustomerPerPage] = useState(1)
  const [queryString, setQueryString] = useState<string>(
    searchWord === DEFAULT_SEATCH_WORD ? '' : searchWord,
  )
  // 検索時にページネーションのフォーカスを1にするためのstate
  const [currentPage, setCurrentPage] = useState<number>(page - 1)
  const [customerCategory, setCustomerCategory] = useState<searchType>(
    isSearchType(searchType) ? searchType : DEFAULT_SEATCH_TYPE,
  )

  // 検索した後の検索状態を表現するstate、このstateを元にパスが作成される
  const [searchCondition, setSearchCondition] = useState({
    word: searchWord || DEFAULT_SEATCH_WORD,
    type: isSearchType(searchType) ? searchType : DEFAULT_SEATCH_TYPE,
    page: page,
  })

  const handleCustomerCategory = useCallback(
    (value: string) => {
      const stringValue = String(value)
      if (isSearchType(stringValue)) {
        setCustomerCategory(stringValue)
      }
    },
    [setCustomerCategory, isSearchType],
  )

  const handleQueryString = useCallback(
    (value: string) => {
      setQueryString(value)
    },
    [setQueryString],
  )

  const handleSearchConditionPage = useCallback(
    (page: number) => {
      setSearchCondition({ ...searchCondition, page: page })
    },
    [searchCondition],
  )

  useEffect(() => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('初回取得に失敗しました。'),
    )

    console.log(page)
    console.log(typeof page)
    getCustomers(axiosInstance, customerCategory, queryString, page)
  }, [])

  const { axiosInstance } = useAxios(
    () => {},
    () => console.log('取得に失敗しました。'),
  )

  const getCustomers = (
    axiosInstance: AxiosInstance,
    type: string,
    word?: string,
    page = 1,
  ) => {
    const params = new URLSearchParams()
    params.append('page', page.toString())
    params.append('type', type)
    word && params.append('word', word)
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}?page=${page}`, { params: params })
      .then((response) => {
        setCustomers(response.data.results)
        setCustomerSize(response.data.count)
        setCustomerPerPage(response.data.perPage)
      })
  }
  const searchCustomers = useCallback(() => {
    getCustomers(axiosInstance, customerCategory, queryString)
    setSearchCondition({
      word: queryString,
      type: customerCategory,
      page: 1,
    })
    setCurrentPage(0)
  }, [getCustomers, setCurrentPage])

  const handleGetCustomer = useCallback(
    (page: number) => {
      getCustomers(axiosInstance, customerCategory, queryString, page)
    },
    [getCustomers],
  )

  return (
    <div>
      <div>
        {title === 'invoice' ? (
          <AdminHeader title="請求情報　-　顧客選択" />
        ) : (
          <AdminHeader title="配信状況　-　顧客選択" />
        )}
      </div>
      <div>
        {title === 'invoice' && (
          <Link to={'/admin/batchProcess'}>
            <Button variant="primary" size="sm" style={{ margin: 20 }}>
              請求書一括画面
            </Button>
          </Link>
        )}
      </div>
      <ChangeCategoryRadio
        handleCustomerCategory={handleCustomerCategory}
        customerCategory={customerCategory}
      />
      <CustomerSearchWindow
        margin={20}
        queryString={queryString}
        handleQueryString={handleQueryString}
        searchCustomers={searchCustomers}
      />
      <div>
        {customerSize > 0 && (
          <CustomerSelectList
            customers={customers}
            searchType={searchCondition.type}
            searchWord={emptyStringToDefaultSearchWordConverter(
              searchCondition.word,
            )}
            pageIndex={searchCondition.page}
          />
        )}
      </div>
      <div>
        {customerSize > 0 && (
          <Pagination
            dataSize={customerSize}
            perPage={customerPerPage}
            handleGetData={handleGetCustomer}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleSearchConditionPage={handleSearchConditionPage}
          />
        )}
      </div>
    </div>
  )
}

export default CustomerSelect
