import ReactPaginate from 'react-paginate'
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { paginateTypes } from 'types/commons'

const LAST_DISPLAY_SIZE = 20
const AROUND_DISPLAY_PAGES = 5

const Pagination: React.FC<paginateTypes> = (props: paginateTypes) => {
  const {
    dataSize,
    perPage,
    handleGetData,
    currentPage,
    setCurrentPage,
    handleSearchConditionPage,
  } = props

  const handlePaginate = (selectedItem: { selected: number }) => {
    const page = selectedItem.selected + 1
    setCurrentPage && setCurrentPage(selectedItem.selected)
    handleSearchConditionPage && handleSearchConditionPage(page)
    typeof handleGetData === 'function' && handleGetData(page)
  }

  const arrowIcon = (iconName: 'left' | 'right') => {
    return iconName === 'left' ? <ArrowLeft /> : <ArrowRight />
  }

  const calculatePageCount = () => {
    return Math.ceil(dataSize / perPage)
  }

  return (
    <div>
      <ReactPaginate
        pageCount={calculatePageCount()}
        marginPagesDisplayed={LAST_DISPLAY_SIZE}
        pageRangeDisplayed={AROUND_DISPLAY_PAGES}
        onPageChange={handlePaginate}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        activeLinkClassName="active"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        previousLabel={arrowIcon('left')}
        nextLabel={arrowIcon('right')}
        disabledClassName="disabled-button"
        forcePage={currentPage}
      />
    </div>
  )
}

export default Pagination
