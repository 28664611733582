import { useParams } from 'react-router-dom'

const EditCustomerDetail: React.FC = () => {
  const { name } = useParams<{ name: string }>()
  return (
    <div>
      <h3>顧客情報修正</h3>
      識別子：{name}
      <br />
      表示名：
      <input type="text" />
      <br />
      郵便番号：
      <input type="text" />
      <br />
      住所：
      <input type="text" />
      <br />
      メール：
      <input type="text" />
      <br />
      担当者名：
      <input type="text" />
      <br />
      閲覧単価：
      <input type="text" />
      <br />
      送信単価：
      <input type="text" />
      <br />
      <button type="submit">作成</button>
    </div>
  )
}

export default EditCustomerDetail
