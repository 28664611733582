import { Button, Modal, Form } from 'react-bootstrap'
import { modalType } from 'types/customers'
import { memo, useState } from 'react'
import EditCustomerError from 'components/admin/editCustomer/EditCustomerError'
import CustomerModalMap from 'components/common/CustomerModalMap'

type InputNumberTypes = {
  placeholder: string
  value: string
  handleSetValue: any
}

const CustomInputNumber: React.FC<InputNumberTypes> = (
  props: InputNumberTypes,
) => {
  const { placeholder, value, handleSetValue } = props
  const [isInvalidValue, setIsInvalidValue] = useState<boolean>(false)

  const formStyle = {
    outline: 'none',
    border: 'solid 0.1rem red',
    borderRadius: '3px',
  }

  return (
    <Form.Control
      type="text"
      placeholder={placeholder}
      value={value}
      style={isInvalidValue ? formStyle : { border: 'solid 1px gray' }}
      onChange={(e) => {
        handleSetValue(e.target.value)
        if (e.target.value === '' || e.target.value.match(/[^0-9.]+/i)) {
          setIsInvalidValue(true)
        } else {
          setIsInvalidValue(false)
        }
      }}
      onBlur={() => {
        setIsInvalidValue(false)
      }}
    />
  )
}

const EditCustomerModal: React.FC<modalType> = memo((props: modalType) => {
  const {
    errors,
    createCustomer,
    handleClose,
    showModal,
    newCustomer,
    setNewCustomer,
    isEdit,
    updateCustomer,
    customerCenter,
    setCustomerCenter,
    mapShow,
    handleMapClose,
    handleMapShow,
    initialCenter,
    uploadIcon,
    setUploadIcon,
    iconUrl,
    isShowIcon,
    setIsShowIcon,
  } = props

  const mapStyle = {
    width: '100%',
    height: '50vh',
  }

  const setAdjustNumberStaff = (value) => {
    setNewCustomer({
      ...newCustomer,
      adjustNumberStaff: value,
    })
  }

  const setViewPrice = (value) => {
    setNewCustomer({
      ...newCustomer,
      viewPrice: value,
    })
  }
  const setSendPrice = (value) => {
    setNewCustomer({
      ...newCustomer,
      sendPrice: value,
    })
  }

  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {isEdit ? (
            <Modal.Title>顧客編集</Modal.Title>
          ) : (
            <Modal.Title>顧客追加</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicCode">
            <Form.Label>識別子</Form.Label>
            <EditCustomerError errors={errors.code} />
            <Form.Control
              type="text"
              placeholder="識別子"
              value={newCustomer.code}
              onChange={(e) =>
                setNewCustomer({ ...newCustomer, code: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>顧客名</Form.Label>
            <EditCustomerError errors={errors.name} />
            <Form.Control
              type="text"
              placeholder="顧客名"
              value={newCustomer.name}
              onChange={(e) =>
                setNewCustomer({ ...newCustomer, name: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCustomerCategory">
            <Form.Label>顧客種別</Form.Label>
            <EditCustomerError errors={errors.category} />
            <Form.Control
              type="text"
              placeholder="顧客種別"
              value={newCustomer.category || ''}
              onChange={(e) =>
                setNewCustomer({ ...newCustomer, category: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicCustomerDisplayCategory"
          >
            <Form.Label>カテゴリ</Form.Label>
            <EditCustomerError errors={errors.displayCategory} />
            <Form.Control
              type="text"
              placeholder="カテゴリ"
              value={newCustomer.displayCategory || ''}
              onChange={(e) =>
                setNewCustomer({
                  ...newCustomer,
                  displayCategory: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>店舗アイコン</Form.Label>
            <Form.Control
              type="file"
              onChange={(e: any) => {
                console.log(e)
                setUploadIcon(e.target.files[0])
                console.log(newCustomer)
              }}
            />
            {!uploadIcon && iconUrl ? (
              <a href="#" onClick={() => setIsShowIcon(true)}>
                現在設定されている店舗アイコンを見る
              </a>
            ) : null}
            {!uploadIcon && isShowIcon ? (
              <img src={iconUrl} width="200" height="auto" />
            ) : null}
          </Form.Group>
          {isEdit && (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>メールアドレス</Form.Label>
                <EditCustomerError errors={errors.email} />
                <Form.Control
                  type="email"
                  placeholder="メールアドレス"
                  value={newCustomer.email || ''}
                  onChange={(e) =>
                    setNewCustomer({
                      ...newCustomer,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPostalCode">
                <Form.Label>郵便番号</Form.Label>
                <EditCustomerError errors={errors.postalCode} />
                <Form.Control
                  type="text"
                  placeholder="郵便番号"
                  value={newCustomer.postalCode || ''}
                  onChange={(e) =>
                    setNewCustomer({
                      ...newCustomer,
                      postalCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAddress">
                <Form.Label>住所</Form.Label>
                <EditCustomerError errors={errors.address} />
                <Form.Control
                  type="text"
                  placeholder="住所"
                  value={newCustomer.address || ''}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, address: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicTelNumber">
                <Form.Label>電話番号</Form.Label>
                <EditCustomerError errors={errors.telNumber} />
                <Form.Control
                  type="text"
                  placeholder="電話番号"
                  value={newCustomer.telNumber || ''}
                  onChange={(e) =>
                    setNewCustomer({
                      ...newCustomer,
                      telNumber: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </>
          )}
          <Form.Group className="mb-3" controlId="formBasicAdjustNumberStaff">
            <Form.Label>スタッフの人数分の調整値</Form.Label>
            <EditCustomerError errors={errors.adjustNumberStaff} />
            <CustomInputNumber
              placeholder="調整値"
              value={newCustomer.adjustNumberStaff}
              handleSetValue={setAdjustNumberStaff}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicViewPrice">
            <Form.Label>閲覧単価</Form.Label>
            <EditCustomerError errors={errors.viewPrice} />
            <CustomInputNumber
              placeholder="閲覧単価"
              value={newCustomer.viewPrice}
              handleSetValue={setViewPrice}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSendPrice">
            <Form.Label>送信単価</Form.Label>
            <EditCustomerError errors={errors.sendPrice} />
            <CustomInputNumber
              placeholder="送信単価"
              value={newCustomer.sendPrice}
              handleSetValue={setSendPrice}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicRootUrl">
            <Form.Label>経路URL</Form.Label>
            <EditCustomerError errors={errors.rootUrl} />
            <Form.Control
              type="text"
              placeholder="経路URL"
              value={newCustomer.rootUrl}
              onChange={(e) =>
                setNewCustomer({ ...newCustomer, rootUrl: e.target.value })
              }
            />
          </Form.Group>
          <Button variant="primary" onClick={handleMapShow}>
            位置情報を追加
          </Button>
          <CustomerModalMap
            style={mapStyle}
            customerCenter={customerCenter}
            setCustomerCenter={setCustomerCenter}
            mapShow={mapShow}
            handleMapClose={handleMapClose}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
            isEdit={isEdit}
            initialCenter={initialCenter}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
          {isEdit ? (
            <Button variant="primary" onClick={updateCustomer}>
              更新
            </Button>
          ) : (
            <Button variant="primary" onClick={createCustomer}>
              作成
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
})

EditCustomerModal.displayName = 'EditCustomerModal'

export default EditCustomerModal
