import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { Navbar, Container } from 'react-bootstrap'
import { useAuthUser } from 'routes/AuthUserContext'
import { headerItemTypes } from 'types/header'
import {
  INVOICE_CUSTOMER_SELECT_PATH,
  SENDED_STATUS_CUSTOMER_SELECT_PATH,
} from 'const/admin/AdminPath'

interface Props {
  title: string
}

const AdminHeader: React.FC<Props> = (props: Props) => {
  const { title } = props
  const authUser = useAuthUser()

  const adminHeaderItems: headerItemTypes = [
    {
      id: 1,
      headerName: '顧客管理',
      link: '/admin/editCustomer',
    },
    {
      id: 2,
      headerName: '請求情報',
      link: INVOICE_CUSTOMER_SELECT_PATH,
    },
    {
      id: 3,
      headerName: '配信状況',
      link: SENDED_STATUS_CUSTOMER_SELECT_PATH,
    },
  ]

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">{title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">メニュー</Nav.Link>
              {adminHeaderItems.map((headerItem) => {
                return (
                  title.includes(headerItem.headerName) || (
                    <Nav.Link key={headerItem.id} href={headerItem.link}>
                      {headerItem.headerName}
                    </Nav.Link>
                  )
                )
              })}
              <Nav.Link href="/logoutAdmin">ログアウト</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              ログイン:{' '}
              <a href="#login">
                {authUser ? authUser.displayName : '未ログイン'}
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default AdminHeader
