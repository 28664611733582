export const SEARCH_TYPE_CODE = '1'
export const SEARCH_TYPE_NAME = '2'
export const SEARCH_TYPE_CATEGORY = '3'

export const DEFAULT_SEATCH_TYPE = SEARCH_TYPE_CODE
export const DEFAULT_SEATCH_WORD = 'search_word'
export const DEFAULT_PAGE_INDEX = 1

export const SEARCH_TYPE_LIST = [
  SEARCH_TYPE_CODE,
  SEARCH_TYPE_NAME,
  SEARCH_TYPE_CATEGORY,
] as const
