// 検証の結果 85の等比数列(85, 170, 340, 680...)ごとにzoomを変更するのが収まりがいい
export const zoomCalc = (radius: number): number | undefined => {
  if (radius <= 85) return 18
  let from = 85
  for (let zoom = 17; zoom > 0; zoom--) {
    const to = from * 2
    if (from < radius && radius <= to) return zoom
    from *= 2
  }
  return
}
