import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { flyers } from 'types/flyerInvoice'
import ConnectAxios from 'utils/connectAxios'
import { invoiceDownload } from 'utils/invoiceDownload'
import InvoiceModal from './InvoiceModal'

const FlyerTable: React.FC<flyers> = (props: flyers) => {
  const {
    flyers,
    setFlyers,
    isUpdate,
    isInvoiceConfirm,
    setIsInvoiceConfirm,
    customerId,
    customerName,
    lastMonth,
  } = props
  const isAdmin = true
  const [total, setTotal] = useState<number>(0)
  const [isUnitPriceUpdate, setIsUnitPriceUpdate] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const limitUnitPrice = 10
  const patchPrice = (flyerIdx: number) => {
    const flyerId = flyers[flyerIdx].id
    const sendPrice = flyers[flyerIdx].sendPrice
    const viewPrice = flyers[flyerIdx].viewPrice
    const data = {
      sendPrice: sendPrice,
      viewPrice: viewPrice,
    }
    if (sendPrice < limitUnitPrice && viewPrice < limitUnitPrice) {
      const { axiosInstance } = ConnectAxios(
        () => {},
        () => console.log('通信に失敗しました。'),
      )
      axiosInstance
        ?.patch(`${ADMIN_URL_CUSTOMER}flyer/${flyerId}/`, data)
        .then((res) => {
          console.log(res.data)
          {
            isUnitPriceUpdate === true && setIsUnitPriceUpdate(false)
          }
          alert('単価が更新されました。')
        })
        .catch((e) => {
          console.log(e.message)
        })
    } else {
      alert(`単価を${limitUnitPrice}円未満に修正してください。`)
    }
  }

  const invoiceConfirm = () => {
    const data = { customer: customerId, confirm_date_year_month: lastMonth }
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance?.post(`${ADMIN_URL_CUSTOMER}invoice/create/`, data)
  }

  const decimalPlaces = 10
  const subTotalCalculate = (
    adjustedSendCount: number,
    sendPrice: number,
    adjustedViewCount: number,
    viewPrice: number,
  ) => {
    const subTotal =
      Math.floor(
        (adjustedSendCount * sendPrice + adjustedViewCount * viewPrice) *
          decimalPlaces,
      ) / decimalPlaces
    return subTotal
  }
  useEffect(() => {
    setIsUnitPriceUpdate(true)
    const totalArr: number[] = []
    flyers.map((flyer) => {
      flyer.subTotal = subTotalCalculate(
        flyer.adjustedSendCount,
        flyer.sendPrice,
        flyer.adjustedViewCount,
        flyer.viewPrice,
      )
      totalArr.push(flyer.subTotal)
    })
    const flyerReducer = (prevValue, currentValue) => prevValue + currentValue
    setTotal(
      Math.floor(
        (totalArr.reduce(flyerReducer, 0) * decimalPlaces) / decimalPlaces,
      ),
    )
  }, [flyers, isUnitPriceUpdate])

  const handleSendPrice = (e: any, flyerIdx: number) => {
    const newFlyers = flyers
    newFlyers[flyerIdx] = {
      ...newFlyers[flyerIdx],
      sendPrice: Number(e.target.value),
    }
    setFlyers(newFlyers)
  }
  const handleViewPrice = (e: any, flyerIdx: number) => {
    const newFlyers = flyers
    newFlyers[flyerIdx] = {
      ...newFlyers[flyerIdx],
      viewPrice: Number(e.target.value),
    }
    setFlyers(newFlyers)
  }
  const handleCloseModal = () => {
    setIsShowModal(false)
  }
  const handleShowModal = () => {
    setIsShowModal(true)
  }
  const handleConfirm = () => {
    invoiceConfirm()
    setIsShowModal(false)
    setIsInvoiceConfirm(true)
  }
  const handleInvoiceDownload = () => {
    invoiceDownload(customerId, customerName, lastMonth, isAdmin)
  }

  const style = {
    fontSize: 13,
    minWidth: 900,
  }
  const input = {
    width: 50,
    height: 30,
  }
  const center: React.CSSProperties = {
    textAlign: 'center',
  }
  const right: React.CSSProperties = {
    textAlign: 'right',
  }

  return (
    <div>
      <InvoiceModal
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        handleFunction={handleConfirm}
        title="確定確認"
        content="請求書を確定"
        buttonName="確定"
      ></InvoiceModal>
      {isUpdate ? <p>当月</p> : <p>先月</p>}
      {!isUpdate && !isInvoiceConfirm && flyers.length > 0 && (
        <Button
          variant="primary"
          size="sm"
          style={{ margin: 10 }}
          onClick={handleShowModal}
        >
          請求書を確定する
        </Button>
      )}
      {!isUpdate && isInvoiceConfirm && flyers.length > 0 && (
        <Button
          variant="primary"
          size="sm"
          style={{ margin: 10 }}
          onClick={handleInvoiceDownload}
        >
          請求書を発行する
        </Button>
      )}
      <Table responsive="sm" style={style}>
        <thead>
          <tr style={center}>
            <th>タイトル</th>
            <th>配信日</th>
            <th>配信数</th>
            {isUpdate ? <th>配信予定単価</th> : <th>配信単価</th>}
            <th>閲覧数</th>
            {isUpdate ? <th>閲覧予定単価</th> : <th>閲覧単価</th>}
            {isUpdate ? <th>利用予定額</th> : <th>利用額</th>}
          </tr>
        </thead>
        <tbody>
          {flyers.map((flyer, flyerIdx) => (
            <tr key={flyerIdx}>
              <td>
                {flyer.title.length > 20
                  ? `${flyer.title.slice(0, 20)}...`
                  : flyer.title}
              </td>
              <td style={center}>{flyer.fromDate}</td>
              <td style={right}>{flyer.adjustedSendCount}</td>
              {isUpdate ? (
                <td style={center}>
                  <input
                    style={input}
                    type="number"
                    step="0.1"
                    min="0"
                    defaultValue={flyer.sendPrice}
                    onChange={() => handleSendPrice(event, flyerIdx)}
                  />
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => patchPrice(flyerIdx)}
                    style={{ marginLeft: 10 }}
                  >
                    更新
                  </Button>
                </td>
              ) : (
                <td style={right}>{flyer.sendPrice}</td>
              )}
              <td style={right}>{flyer.adjustedViewCount}</td>
              {isUpdate ? (
                <td style={center}>
                  <input
                    style={input}
                    type="number"
                    step="0.1"
                    min="0"
                    defaultValue={flyer.viewPrice}
                    onChange={() => handleViewPrice(event, flyerIdx)}
                  />
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => patchPrice(flyerIdx)}
                    style={{ marginLeft: 10 }}
                  >
                    更新
                  </Button>
                </td>
              ) : (
                <td style={right}>{flyer.viewPrice}</td>
              )}
              <td style={right}>{flyer.subTotal}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={{ fontSize: 14, fontWeight: 'bold' }}>
            {isUpdate ? (
              <td colSpan={6}>利用予定額 合計</td>
            ) : (
              <td colSpan={6}>利用額 合計</td>
            )}
            <td style={right}>{total}</td>
          </tr>
        </tfoot>
      </Table>
      <br />
    </div>
  )
}

export default FlyerTable
