import CustomerList from 'components/admin/editCustomer/CustomerList'
import Pagination from 'utils/Pagination'
import { customersWithPagenation } from 'types/customers'
import { memo } from 'react'

const Customers: React.FC<customersWithPagenation> = memo(
  (props: customersWithPagenation) => {
    const {
      customers,
      customerSize,
      customerPerPage,
      handleGetCustomer,
      handleEditModalShow,
      currentPage,
      setCurrentPage,
    } = props

    return (
      <div>
        <div>
          {customerSize > 0 && (
            <CustomerList
              customers={customers}
              handleEditModalShow={handleEditModalShow}
            />
          )}
        </div>
        <div>
          {customerSize > 0 && (
            <Pagination
              dataSize={customerSize}
              perPage={customerPerPage}
              handleGetData={handleGetCustomer}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    )
  },
)

Customers.displayName = 'Customer'

export default Customers
