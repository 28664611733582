import {
  GoogleMap,
  Circle,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import { deliverDetailMapType } from 'types/map'
import { Spinner } from 'react-bootstrap'

const DeliverDetailMap: React.FC<deliverDetailMapType> = (
  props: deliverDetailMapType,
) => {
  const { radius, locations, center, zoom } = props
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  })

  const options = {
    strokeColor: '#808080',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#808080',
    fillOpacity: 0.35,
    visible: true,
    zIndex: 1,
  }

  const style = {
    width: '100%',
    height: '60vh',
  }

  const renderMap = () => {
    return (
      <GoogleMap id="map" mapContainerStyle={style} center={center} zoom={zoom}>
        {locations &&
          locations.map((location) => (
            <Marker
              icon={{
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: location.clicked ? 'orange' : 'blue',
                strokeWeight: 0.5,
                fillOpacity: 1.0,
                scale: 3,
              }}
              key={location.key}
              position={location}
            />
          ))}
        <Circle radius={radius} center={center} options={options} />
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Spinner animation="border" variant="primary" />
  )
}

export default DeliverDetailMap
