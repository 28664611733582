import { memo } from 'react'
import { Button, Form } from 'react-bootstrap'
import { customerSearchWindowType } from 'types/customers'

const CustomerSearchWindow = (props: customerSearchWindowType) => {
  const mediaQuery = window.matchMedia('(min-width: 768px)')
  const onChangeQueryString = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleQueryString(event.target.value)
  }

  return (
    <div style={{ margin: `${props.margin}px`, display: 'flex' }}>
      {mediaQuery.matches ? (
        <Form.Control
          size="lg"
          type="text"
          value={props.queryString}
          onChange={onChangeQueryString}
          placeholder="検索ワードを入力してください"
          style={{ width: 'calc(100%-105px)', marginRight: '5px' }}
        />
      ) : (
        <Form.Control
          type="text"
          value={props.queryString}
          onChange={onChangeQueryString}
          placeholder="検索ワードを入力してください"
          style={{ width: 'calc(100%-105px)', marginRight: '5px' }}
        />
      )}

      <Button
        variant="primary"
        style={{ width: '100px' }}
        onClick={() => props.searchCustomers()}
      >
        検索
      </Button>
    </div>
  )
}

export default memo(CustomerSearchWindow)
