import axios from 'axios'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { getBaseUrl } from 'utils/baseAxios'
import PasswordResetError from './PasswordResetError'

/* eslint-disable */
const emailErrorEngText =
  "We couldn't find an account associated with that email. Please try a different e-mail address."
const emailErrorJpnText =
  '入力されたメールアドレスに関連するアカウントが見つかりませんでした。別のメールアドレスを試してみてください。'

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState([])
  const handleError = (e) => {
    if (e.response.data.email[0] === emailErrorEngText) {
      e.response.data.email[0] = emailErrorJpnText
    }
    setEmailError(e.response.data.email)
  }
  const inputChange = (event) => {
    setEmail(event.target.value)
  }
  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
  })
  const handlePasswordRest = () => {
    const data = { email: email }
    axiosInstance
      .post('api/password_reset/', data)
      .then(() => {
        setEmailError([])
        alert('メールが送信されました。')
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response.data.email)
          handleError(e)
        } else {
          console.error(e)
        }
      })
  }

  return (
    <div>
      <h2>パスワード再設定</h2>
      <Form>
        <Form.Group className="mb-3" controlId="formUserName">
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            name="username"
            type="text"
            value={email}
            onChange={(e) => inputChange(e)}
            placeholder="登録しているメールアドレスを入力してください"
          />
        </Form.Group>
        <PasswordResetError errors={emailError} />
        <Button variant="primary" onClick={handlePasswordRest}>
          送信
        </Button>
      </Form>
    </div>
  )
}

export default PasswordReset
