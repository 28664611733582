import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { invoiceDownload } from 'utils/invoiceDownload'
import { Filter } from 'react-bootstrap-icons'
import { invoiceTableTypes } from 'types/invoice'

const InvoiceTable: React.FC<invoiceTableTypes> = (
  props: invoiceTableTypes,
) => {
  const {
    isFilteredColumns,
    invoices,
    yearMonth,
    handleFilter,
    handleCheckDateShowModal,
  } = props
  const isAdmin = true
  const tableStyle: React.CSSProperties = {
    fontSize: 13,
    minWidth: 700,
    textAlign: 'center',
  }
  const pointer: React.CSSProperties = {
    cursor: 'pointer',
  }
  return (
    <Table responsive="sm" style={tableStyle}>
      <thead>
        <tr>
          <th>顧客名</th>
          <th>確定日</th>
          {isFilteredColumns.nullOnlyDownloadAt ? (
            <th style={pointer} onClick={() => handleFilter('downloadAt')}>
              確認状況 <Filter />
            </th>
          ) : (
            <th style={pointer} onClick={() => handleFilter('downloadAt')}>
              確認状況
            </th>
          )}
          {isFilteredColumns.nullOnlyPaymentCheckDate ? (
            <th
              style={pointer}
              onClick={() => handleFilter('paymentCheckDate')}
            >
              入金状況 <Filter />
            </th>
          ) : (
            <th
              style={pointer}
              onClick={() => handleFilter('paymentCheckDate')}
            >
              入金状況
            </th>
          )}
          <th>請求額</th>
          <th>請求書確認</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr key={invoice.id}>
            <td>{invoice.customerName}</td>
            <td>{invoice.createdAt}</td>
            {invoice.downloadAt ? (
              <td>{invoice.downloadAt}</td>
            ) : (
              <td>未確認</td>
            )}
            {invoice.paymentCheckDate ? (
              <td>{invoice.paymentCheckDate}</td>
            ) : (
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    handleCheckDateShowModal(invoice.id)
                  }}
                >
                  入金確認
                </Button>
              </td>
            )}
            <td>¥{invoice.price}</td>
            <td>
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  invoiceDownload(
                    invoice.customer,
                    invoice.customerName,
                    yearMonth,
                    isAdmin,
                  )
                }
              >
                請求書ダウンロード
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
export default InvoiceTable
