import { Route, Switch } from 'react-router-dom'
import EditCustomer from 'components/admin/editCustomer/EditCustomer'
import AdminHome from 'components/admin/AdminHome'
import ClickedMapView from 'components/admin/ClickedMapView'
import CustomerSelect from 'components/admin/CustomerSelect'
import CustomerSendedStatusList from 'components/admin/sendedStatus/CustomerSendedStatusList'
import FlyerList from 'components/admin/invoice/FlyerList'
import BatchProcess from 'components/admin/invoice/BatchProcess'

const AdminRoutes: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/admin" component={AdminHome} />
        <Route path="/admin/editCustomer" component={EditCustomer} />
        <Route
          path="/admin/customerSelect/:title/:searchType/:searchWord/:pageIndex"
          component={CustomerSelect}
        />
        <Route
          path="/admin/invoice/:searchType/:searchWord/:pageIndex/:customerId"
          component={FlyerList}
        />
        <Route path="/admin/batchProcess/" component={BatchProcess} />
        <Route
          path="/admin/sendedStatus/:searchType/:searchWord/:pageIndex/:customerId"
          component={CustomerSendedStatusList}
        />
        <Route path="/admin/clickedMapView/:id" component={ClickedMapView} />
      </Switch>
    </div>
  )
}
export default AdminRoutes
