import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLogout } from 'routes/AuthUserContext'

const Logout: React.FC = () => {
  const history = useHistory()
  const logout = useLogout()

  useEffect(() => {
    logout()
  }, [])

  return (
    <div>
      ログアウトしました
      <br />
      <Button variant="primary" onClick={() => history.push('/login')}>
        ログインページへ戻る
      </Button>
    </div>
  )
}

export default Logout
