// import { useParams } from 'react-router-dom
import { useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { customerMapTypes } from 'types/map'
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap'
import Geocode from 'react-geocode'

Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)

const CustomerModalMap: React.FC<customerMapTypes> = (
  props: customerMapTypes,
) => {
  const {
    style,
    customerCenter,
    setCustomerCenter,
    mapShow,
    handleMapClose,
    newCustomer,
    setNewCustomer,
    isEdit,
    initialCenter,
  } = props

  const nagano = {
    lat: 36.643808,
    lng: 138.188202,
  }

  const [searchValue, setSearchValue] = useState('')

  const searchLocationInfo = () => {
    Geocode.fromAddress(searchValue).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        setCustomerCenter({ lat: lat, lng: lng })
        setNewCustomer({ ...newCustomer, lat: lat, lng: lng })
      },
      (error) => {
        console.error(error)
      },
    )
    setSearchValue('')
  }

  const initLocationInfo = () => {
    if (isEdit) {
      setCustomerCenter(initialCenter)
      setNewCustomer({
        ...newCustomer,
        lat: initialCenter.lat,
        lng: initialCenter.lng,
      })
    } else {
      setCustomerCenter(nagano)
      setNewCustomer({ ...newCustomer, lat: nagano.lat, lng: nagano.lng })
    }
  }

  const handleMarkerChanged = (e) => {
    setCustomerCenter({ lat: e.latLng?.lat(), lng: e.latLng?.lng() })
    setNewCustomer({
      ...newCustomer,
      lat: e.latLng?.lat(),
      lng: e.latLng?.lng(),
    })
  }

  return (
    <Modal show={mapShow} onHide={handleMapClose} size="lg">
      <Modal.Header>
        <Modal.Title>位置情報を入力してください</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="住所を入力してください"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            variant="primary"
            className="ml-3"
            id="button-addon2"
            onClick={searchLocationInfo}
          >
            検索する
          </Button>
        </InputGroup>
        <LoadScript
          googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
        >
          <GoogleMap
            id="map"
            mapContainerStyle={style}
            center={customerCenter}
            zoom={17}
          >
            <Marker
              position={customerCenter}
              draggable={true}
              onDragEnd={handleMarkerChanged}
            />
          </GoogleMap>
        </LoadScript>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleMapClose}>
          閉じる
        </Button>
        <Button variant="secondary" onClick={initLocationInfo}>
          元に戻す
        </Button>
        <Button variant="primary" onClick={handleMapClose}>
          追加する
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomerModalMap
