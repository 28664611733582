import { Alert } from 'react-bootstrap'
import { loginErrorTypes } from 'types/auth'

const LoginError: React.FC<loginErrorTypes> = (props: any | null) => {
  const { errorMsg } = props

  return <div>{errorMsg && <Alert variant="danger">{errorMsg}</Alert>}</div>
}

export default LoginError
