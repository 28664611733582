import Table from 'react-bootstrap/Table'
import { customers } from 'types/customers'
import { memo } from 'react'

const CostomerList: React.FC<customers> = memo((props: customers) => {
  const { customers, handleEditModalShow } = props

  return (
    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>id</th>
            <th>顧客名</th>
            <th>識別子</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td>
                <a
                  href="#"
                  onClick={() => {
                    handleEditModalShow(customer.id)
                  }}
                >
                  {customer.id}
                </a>
              </td>
              <td>{customer.name}</td>
              <td>{customer.code}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
})

CostomerList.displayName = 'CostomerList'

export default CostomerList
