import { Button } from 'react-bootstrap'
import { urlParamsType } from '../../types/customers'

const BackButton = (props: Omit<urlParamsType, 'customers'>) => {
  const { title, searchType, searchWord, pageIndex } = props

  return (
    <span style={{ display: 'flex', justifyContent: 'end' }}>
      <a
        href={`/admin/customerSelect/${title}/${searchType}/${searchWord}/${pageIndex}/`}
      >
        <Button variant="primary">戻る</Button>
      </a>
    </span>
  )
}

export default BackButton
