import {
  SEARCH_TYPE_NAME,
  SEARCH_TYPE_CODE,
  SEARCH_TYPE_CATEGORY,
} from 'const/admin/SeacrhCustomer'
import { memo } from 'react'
import { changeCategoryRadioType } from '../../types/customers'

const ChangeCategoryRadio = (props: changeCategoryRadioType) => {
  const { handleCustomerCategory, customerCategory } = props
  const onChangeCategoryRadio = (event) => {
    handleCustomerCategory(event.target.value)
  }

  return (
    <div style={{ display: 'flex', margin: 10 }}>
      <label style={{ marginRight: '1rem', marginBottom: '0' }}>
        <input
          style={{ margin: 5 }}
          type="radio"
          value={SEARCH_TYPE_CODE}
          onChange={onChangeCategoryRadio}
          checked={customerCategory === SEARCH_TYPE_CODE}
        />
        識別子
      </label>
      <label style={{ marginRight: '1rem', marginBottom: '0' }}>
        <input
          style={{ margin: 5 }}
          type="radio"
          value={SEARCH_TYPE_NAME}
          onChange={onChangeCategoryRadio}
          checked={customerCategory === SEARCH_TYPE_NAME}
        />
        顧客名
      </label>
      <label style={{ marginRight: '1rem', marginBottom: '0' }}>
        <input
          style={{ margin: 5 }}
          type="radio"
          value={SEARCH_TYPE_CATEGORY}
          onChange={onChangeCategoryRadio}
          checked={customerCategory === SEARCH_TYPE_CATEGORY}
        />
        カテゴリ
      </label>
    </div>
  )
}

export default memo(ChangeCategoryRadio)
