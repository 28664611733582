import { Modal, Button } from 'react-bootstrap'

type confirmDialogType = {
  title: string
  children: React.ReactNode
  onSubmit: () => void
  onClose: () => void
  submitText: string
  isOpen: boolean
}

const ConfirmDialog = (props: confirmDialogType): JSX.Element => {
  return (
    <Modal
      show={props.isOpen}
      onHide={props.onClose}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={props.onSubmit}>
          {props.submitText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDialog
