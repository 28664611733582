import { Route, Switch } from 'react-router-dom'
import CustomerHome from 'components/customer/CustomerHome'
import CustomerFlyer from 'components/customer/editFlyer/CustomerFlyer'
import EditFlyer from 'components/customer/editFlyer/EditFlyer'
import DeliverDetail from 'components/customer/DeliverDetail'
import CustomerInvoiceDownload from 'components/customer/CustomerInvoiceDownload'

const CustomerRoutes: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/customer" component={CustomerHome} />
        <Route path="/customer/flyer" component={CustomerFlyer} />
        <Route exact path="/customer/editFlyer" component={EditFlyer} />
        <Route exact path="/customer/editFlyer/:id" component={EditFlyer} />
        <Route path="/customer/deliverDetail/:id" component={DeliverDetail} />
        <Route
          path="/customer/invoice/:yearMonth"
          component={CustomerInvoiceDownload}
        />
      </Switch>
    </div>
  )
}

export default CustomerRoutes
