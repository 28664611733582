import {
  BrowserRouter as Router,
  RouteProps,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom'
import './App.css'
import AdminRoutes from './routes/AdminRoutes'
import CustomerRoutes from './routes/CustomerRoutes'
import { Container } from 'react-bootstrap'
import Login from './components/common/auth/Login'
import Logout from './components/common/auth/Logout'
import LoginAdmin from './components/common/auth/LoginAdmin'
import LogoutAdmin from './components/common/auth/LogoutAdmin'
import AuthUserProvider, {
  useAuthUser,
  useLogout,
  useVerify,
  useIsVerified,
} from './routes/AuthUserContext'
import { authUserTypes, verifyTypes } from './types/auth'
import PasswordReset from 'components/customer/PasswordReset'
import PasswordResetConfirm from 'components/customer/PasswordResetConfirm'

const UnAuthRoute: React.FC<RouteProps> = ({ ...props }) => {
  const authUser = useAuthUser()
  const isAuthenticated = authUser ? true : false

  let location: any = ''

  if (useLocation() !== undefined) {
    location = useLocation().state
  }

  if (isAuthenticated) {
    if (props.path === '/logout') {
      // 認証情報が存在しても、ログアウトは除外
      return <Route {...props} />
    } else {
      // ログインはHOME画面へリダイレクト
      console.log(`ログイン済みのユーザーは${props.path}へはアクセスできません`)
      return <Redirect to={location?.from ?? '/customer'} />
    }
  } else {
    return <Route {...props} />
  }
}

const UnAuthRouteForAdmin: React.FC<RouteProps> = ({ ...props }) => {
  const authUser = useAuthUser()

  let location: any = ''

  if (useLocation() !== undefined) {
    location = useLocation().state
  }

  if (isAdminAuthenticated(authUser)) {
    if (props.path === '/logoutAdmin') {
      // 認証情報が存在しても、ログアウトは除外
      return <Route {...props} />
    } else {
      // ログインはHOME画面へリダイレクト
      console.log(`ログイン済みのユーザーは${props.path}へはアクセスできません`)
      return <Redirect to={location?.from ?? '/admin'} />
    }
  } else {
    return <Route {...props} />
  }
}

const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const authUser = useAuthUser()
  const verify = useVerify()
  const isVerified = useIsVerified()

  const isAuthenticated = authUser ? true : false
  if (isAuthenticated) {
    if (authUser?.isAdmin) {
      // 管理者権限の場合は、強制的にログインから
      const logout = useLogout()
      logout()
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location?.pathname } }}
        />
      )
    }

    // トークン認証
    const verifyVal: verifyTypes = {
      token: authUser ? authUser.loginToken : '',
    }
    verify(verifyVal)

    if (isVerified) {
      console.log('isVerified')
      return <Route {...props} />
    } else {
      console.log('is not Verify')
      return <></>
    }
  } else {
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: props.location?.pathname } }}
      />
    )
  }
}

const PrivateRouteForAdmin: React.FC<RouteProps> = ({ ...props }) => {
  const authUser = useAuthUser()
  const verify = useVerify()
  const isVerified = useIsVerified()

  if (isAdminAuthenticated(authUser)) {
    // トークン認証
    const verifyVal: verifyTypes = {
      token: authUser ? authUser.loginToken : '',
    }
    // トークン認証
    verify(verifyVal)
    if (isVerified) {
      console.log('isVerified(admin)')
      return <Route {...props} />
    } else {
      console.log('is not Verify(admin)')
      return <></>
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: '/loginAdmin',
          state: { from: props.location?.pathname },
        }}
      />
    )
  }
}

const isAdminAuthenticated = (authUser: authUserTypes | null): boolean => {
  let isAuthenticated = false

  if (authUser && authUser.isAdmin) {
    isAuthenticated = true
  }

  return isAuthenticated
}

function App(): React.ReactElement {
  return (
    <AuthUserProvider>
      <Router>
        <Container fluid style={{ marginTop: 30 }}>
          <div className="App"></div>
          <Switch>
            {process.env.REACT_APP_LANDING_URL && (
              <UnAuthRoute
                exact
                path="/"
                component={() => {
                  window.location.href = `${process.env.REACT_APP_LANDING_URL}`
                  return null
                }}
              />
            )}

            <UnAuthRoute exact path="/login" component={Login} />
            <UnAuthRoute exact path="/logout" component={Logout} />
            <UnAuthRouteForAdmin
              exact
              path="/loginAdmin"
              component={LoginAdmin}
            />
            <UnAuthRouteForAdmin
              exact
              path="/logoutAdmin"
              component={LogoutAdmin}
            />
            <PrivateRouteForAdmin path="/admin" component={AdminRoutes} />
            <PrivateRoute path="/customer" component={CustomerRoutes} />
            <UnAuthRoute
              exact
              path="/passwordReset"
              component={PasswordReset}
            />
            <UnAuthRoute
              exact
              path="/passwordReset/:customerName/:token"
              component={PasswordResetConfirm}
            />
          </Switch>
        </Container>
      </Router>
    </AuthUserProvider>
  )
}

export default App
