import { Link } from 'react-router-dom'
import CustomerHeader from 'components/customer/CustomerHeader'
import { useAuthUser } from 'routes/AuthUserContext'

const CustomerHome: React.FC = () => {
  const authUser = useAuthUser()

  return (
    <div>
      <CustomerHeader
        title={`顧客メニュー ： ${authUser ? authUser.customerName : ''}`}
      />
      <ul>
        <li>
          <Link to={'/customer/flyer'}>情報管理</Link>
        </li>
      </ul>
    </div>
  )
}

export default CustomerHome
