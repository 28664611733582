export const options = [
  {
    key: 'FOOD',
    value: 1,
  },
  {
    key: 'LIFE',
    value: 2,
  },
  {
    key: '特集',
    value: 3,
  },
]
