import { Container, Table } from 'react-bootstrap'
import DeliverDetailMap from 'components/common/DeliverDetailMap'
import { useEffect, useState } from 'react'
import useAxios from 'hocks/axios'
import { AxiosInstance } from 'axios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CustomerHeader from './CustomerHeader'
import { useAuthUser } from 'routes/AuthUserContext'
import { zoomCalc } from 'utils/zoomCalc'

const DeliverDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [deliverInfo, setDeliverInfo] = useState({
    radius: 0,
    title: '',
    content: '',
    location: [],
  })
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  })
  const [zoom, setZoom] = useState<number | undefined>(15)

  const authUser = useAuthUser()

  useEffect(() => {
    const { axiosInstance } = useAxios(
      () => {},
      () => console.log('取得に失敗しました。'),
    )
    getDeliverDetail(axiosInstance)
  }, [])

  const getDeliverDetail = (axiosInstance: AxiosInstance) => {
    axiosInstance
      ?.get(`api-admin/flyer/deliver_detail/${id}/`)
      .then((res) => {
        setDeliverInfo({
          radius: res.data.radius,
          title: res.data.title,
          content: res.data.content,
          location: res.data.location,
        })
        console.log(res.data)
        setCenter({
          lat: res.data.lat,
          lng: res.data.lng,
        })
        setZoom(zoomCalc(res.data.radius))
      })
      .catch(() => {
        console.log(id)
      })
  }

  return (
    <>
      <CustomerHeader
        title={`情報管理 - 配信状況 ： ${
          authUser ? authUser.customerName : ''
        }`}
      />
      <Container className="mt-5">
        <h2>配信状況</h2>
        <hr />
        <p>
          <span style={{ color: 'orange' }}>オレンジ</span> ：
          広告の配信位置(閲覧済み)
        </p>
        <p>
          <span style={{ color: 'blue' }}>青</span> ： 広告の配信位置
        </p>
        <DeliverDetailMap
          radius={deliverInfo?.radius}
          locations={deliverInfo?.location}
          center={center}
          zoom={zoom}
        />
        <Table striped bordered hover responsive className="mt-4">
          <tbody>
            <tr>
              <td>タイトル</td>
              <td>{deliverInfo.title}</td>
            </tr>
            <tr>
              <td>配信内容</td>
              <td
                dangerouslySetInnerHTML={{ __html: deliverInfo.content }}
              ></td>
            </tr>
          </tbody>
        </Table>
        <p>
          <Link to={'/customer/flyer'}>チラシ一覧に戻る</Link>
        </p>
      </Container>
    </>
  )
}

export default DeliverDetail
