import axios from 'axios'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { PasswordResetTypes } from 'types/passwordReset'
import { getBaseUrl } from 'utils/baseAxios'
import PasswordResetError from './PasswordResetError'

const PasswordResetConfirm: React.FC = () => {
  const { customerName, token } = useParams<PasswordResetTypes>()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string[] | []>([])
  const [tokenError, setTokenError] = useState<string[] | []>([])
  const history = useHistory()
  const handlePasswordError = (e, password: string) => {
    if (e.response.data.password) {
      if (password.match(/^[A-Za-z0-9]*$/)) {
        setPasswordError(e.response.data.password)
      } else {
        setPasswordError(['パスワードは半角英数で入力してください。'])
      }
    }
  }
  const handleTokenError = (e) => {
    if (e.response.data.detail) {
      setTokenError([`有効なトークンが${e.response.data.detail}`])
    }
  }
  const inputChange = (event) => {
    setPassword(event.target.value)
  }
  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
  })
  const handlePasswordRest = () => {
    const data = { password: password, token: token }
    axiosInstance
      .post('api/password_reset/validate_token/', { token: token })
      .then(() => {
        axiosInstance
          .post('api/password_reset/confirm/', data)
          .then(() => {
            alert('パスワードが更新されました。')
            history.push('/login')
          })
          .catch((e) => {
            if (e.response) {
              handlePasswordError(e, password)
              console.log(e.response)
            } else {
              console.error(e.message)
            }
          })
      })
      .catch((e) => {
        if (e.response) {
          handleTokenError(e)
          console.log(e.response)
        } else {
          console.error(e.message)
        }
      })
  }

  return (
    <div>
      <h2>パスワード再設定</h2>
      <p>{customerName} 様</p>
      <Form>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>新しいパスワード</Form.Label>
          <Form.Control
            name="password"
            type="password"
            value={password}
            onChange={(e) => inputChange(e)}
            placeholder="新しいパスワードを入力してください"
          />
        </Form.Group>
        <PasswordResetError errors={passwordError} />
        <PasswordResetError errors={tokenError} />
        <Button variant="primary" onClick={handlePasswordRest}>
          送信
        </Button>
      </Form>
    </div>
  )
}

export default PasswordResetConfirm
