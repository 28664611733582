import { Link, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import { urlParamsType } from '../../types/customers'

const CustomerSelectList = (props: Omit<urlParamsType, 'title'>) => {
  const { title } = useParams<{ title: string }>()
  const { customers, searchType, searchWord, pageIndex } = props

  return (
    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>id</th>
            <th>顧客名</th>
            <th>識別子</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td>
                <Link
                  to={`/admin/${title}/${searchType}/${searchWord}/${pageIndex}/${customer.id}`}
                >
                  {customer.id}
                </Link>
              </td>
              <td>{customer.name}</td>
              <td>{customer.code}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default CustomerSelectList
