import BaseAxios from './baseAxios'
import {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from 'axios'
import { camelizeKeys } from 'humps'
import {
  getAuthUserItemOnLocalStrage,
  setAuthUserItemOnLocalStrage,
  deleteAuthUserOnLocalStrage,
} from 'routes/AuthUserContext'
import { refreshTypes } from 'types/auth'

const ConnectAxios = (
  successHookCallback?: (response: AxiosResponse) => void,
  failedHookCallback?: (error: AxiosError) => void,
): {
  axiosInstance: AxiosInstance
  isLoading: boolean
} => {
  let isLoading = false
  const axiosInstance: AxiosInstance = BaseAxios
  axiosInstance.interceptors.request.use((request) => {
    isLoading = true
    return request
  })

  let isRetry = false
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      console.log('response', response)
      isLoading = false
      if (
        response.data &&
        response.headers['content-type'] === 'application/json'
      ) {
        response.data = camelizeKeys(response.data)
      }
      console.log('camelize response', response)
      typeof successHookCallback === 'function' && successHookCallback(response)
      return response
    },
    (error: AxiosError) => {
      console.log('response error', error)
      isLoading = false
      if (error?.response) {
        if (
          error.config &&
          error.response.status === 401 &&
          error.response.data.code === 'token_not_valid' &&
          !isRetry
        ) {
          isRetry = true
          return refreshToken(axiosInstance, error.config)
        } else {
          if (
            error?.response.data &&
            error?.response.headers['content-type'] === 'application/json'
          ) {
            error.response.data = camelizeKeys(error?.response.data)
          }
          console.log('camelize error', error)
          typeof failedHookCallback === 'function' && failedHookCallback(error)
          return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    },
  )

  return { isLoading, axiosInstance }
}

const refreshToken = (
  axiosInstance: AxiosInstance,
  originalRequest: AxiosRequestConfig,
) => {
  console.log('refreshToken')
  console.log('access Token', getAuthUserItemOnLocalStrage('loginToken'))
  console.log('refresh Token', getAuthUserItemOnLocalStrage('refreshToken'))

  const refreshVal: refreshTypes = {
    refresh: getAuthUserItemOnLocalStrage('refreshToken'),
  }

  return axiosInstance
    ?.post('/api-admin/auth/jwt/refresh', refreshVal)
    .then((res) => {
      const accessToken = res.data.access
      setAuthUserItemOnLocalStrage('loginToken', accessToken)

      const config = originalRequest
      config.headers['Authorization'] = `JWT ${getAuthUserItemOnLocalStrage(
        'loginToken',
      )}`

      console.log('retry request！！')
      return axiosInstance.request(config)
    })
    .catch((error) => {
      console.log('トークンの取得に失敗しました')
      if (error.response.status === 401) {
        // 401の場合は、Verify時のリフレッシュを期待して何もしない
        console.log('refreshToken 401')
      } else {
        deleteAuthUserOnLocalStrage()
      }
      return Promise.reject(error)
    })
}

export default ConnectAxios
