import { useState, useEffect, useCallback } from 'react'
import { Switch, Route } from 'react-router-dom'
import ConnectAxios from 'utils/connectAxios'
import EditCustomerDetail from 'components/admin/editCustomer/EditCustomerDetail'
import Costomers from 'components/admin/editCustomer/Customers'
import useAxios from 'hocks/axios'
import ClipLoader from 'react-spinners/ClipLoader'
import { AxiosInstance } from 'axios'
import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import AdminHeader from 'components/admin/AdminHeader'
import { Button } from 'react-bootstrap'
import { customerErrorHandling } from 'types/customers'
import EditCustomerModal from './EditCustomerModal'
import { decamelizeKeys } from 'humps'
import SearchWindow from '../CustomerSearchWindow'
import ChangeCategoryRadio from '../ChangeCategoryRadio'
import { SEARCH_TYPE_CODE } from 'const/admin/SeacrhCustomer'

const EditCustomer: React.FC = () => {
  const [customers, setCustomers] = useState<any>([])
  const [customerSize, setCustomerSize] = useState(1)
  const [customerPerPage, setCustomerPerPage] = useState(1)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [customer_id, setCustomerId] = useState(0)
  const [uploadIcon, setUploadIcon] = useState<any>(null)
  const [iconUrl, setIconUrl] = useState<string>('')
  const [isShowIcon, setIsShowIcon] = useState<boolean>(false)
  const [queryString, setQueryString] = useState<string>('')
  // 検索時にページネーションのフォーカスを1にするためのstate
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [customerCategory, setCustomerCategory] =
    useState<string>(SEARCH_TYPE_CODE)

  const handleCustomerCategory = useCallback(
    (value: string) => {
      setCustomerCategory(value)
    },
    [setCustomerCategory],
  )

  const handleQueryString = useCallback(
    (value: string) => {
      setQueryString(value)
    },
    [setQueryString],
  )

  const error: customerErrorHandling = {
    code: [],
    name: [],
    email: [],
    postalCode: [],
    address: [],
    telNumber: [],
    displayCategory: [],
    category: [],
    viewPrice: [],
    sendPrice: [],
    adjustNumberStaff: [],
    rootUrl: [],
  }
  const [errors, setErrors] = useState(error)

  const initialCustomer: {
    code: string
    name: string
    viewPrice: number
    sendPrice: number
    email: string
    postalCode: string
    address: string
    telNumber: string
    lat: number
    lng: number
    displayCategory: string
    category: string
    adjustNumberStaff: number
    rootUrl: string
  } = {
    code: '',
    name: '',
    viewPrice: 0.5,
    sendPrice: 1.0,
    email: '',
    postalCode: '',
    address: '',
    telNumber: '',
    lat: 36.643808,
    lng: 138.188202,
    displayCategory: '',
    category: '',
    adjustNumberStaff: 0,
    rootUrl: '',
  }

  const nagano = {
    lat: 36.643808,
    lng: 138.188202,
  }

  const [customerCenter, setCustomerCenter] = useState(nagano)

  const [mapShow, setMapShow] = useState(false)

  const handleMapClose = () => {
    setMapShow(false)
  }
  const handleMapShow = () => {
    setMapShow(true)
  }

  const [newCustomer, setNewCustomer] = useState(initialCustomer)

  const [initialCenter, setInitialCenter] = useState(nagano)

  useEffect(() => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('初回取得に失敗しました。'),
    )

    getCustomers(axiosInstance, SEARCH_TYPE_CODE, queryString)
  }, [])

  const { axiosInstance, isLoading } = useAxios(
    () => {},
    () => console.log('取得に失敗しました。'),
  )

  const getCustomers = (
    axiosInstance: AxiosInstance,
    type: string,
    word?: string,
    page = 1,
  ) => {
    const params = new URLSearchParams()
    params.append('page', page.toString())
    params.append('type', type)
    word && params.append('word', word)
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}`, { params: params })
      .then((response) => {
        console.log(response)
        setCustomers(response.data.results)
        setCustomerSize(response.data.count)
        setCustomerPerPage(response.data.perPage)
      })
  }

  const searchCustomers = useCallback(() => {
    getCustomers(axiosInstance, customerCategory, queryString)
    setCurrentPage(0)
  }, [getCustomers, setCurrentPage])

  const handleGetCustomer = useCallback(
    (page: number) => {
      getCustomers(axiosInstance, customerCategory, queryString, page)
    },
    [getCustomers],
  )

  const handleClose = () => {
    setShowModal(false)
    setIsEdit(false)
    setCustomerCenter(nagano)
    setUploadIcon(null)
    setIsShowIcon(false)
  }
  const handleShow = () => {
    setIconUrl('')
    setShowModal(true)
    setErrors(error)
    setNewCustomer(initialCustomer)
  }
  const handleEditModalShow = (customer_id) => {
    // setShowModal(true)
    setErrors(error)
    setCustomerId(customer_id)

    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance?.get(`/api-admin/customer/${customer_id}/`).then((res) => {
      console.log(res.data)
      setNewCustomer({
        name: res.data.name,
        code: res.data.code,
        viewPrice: res.data.viewPrice,
        sendPrice: res.data.sendPrice,
        email: res.data.email,
        postalCode: res.data.postalCode,
        address: res.data.address,
        telNumber: res.data.telNumber,
        lat: res.data.lat,
        lng: res.data.lng,
        displayCategory: res.data.displayCategory,
        category: res.data.category,
        adjustNumberStaff: res.data.adjustNumberStaff,
        rootUrl: res.data.rootUrl,
      })
      setIconUrl(res.data.icon)
      setCustomerCenter({ lat: res.data.lat, lng: res.data.lng })
      setInitialCenter({ lat: res.data.lat, lng: res.data.lng })
    })
    setIsEdit(true)
    setShowModal(true)
  }

  const handleError = (e) => {
    const catchError: any = {}
    if (e.response.data.message) {
      catchError.code = e.response.data.message
    } else {
      e.response.data.code
        ? (catchError.code = e.response.data.code)
        : (catchError.code = [])
    }
    e.response.data.name
      ? (catchError.name = e.response.data.name)
      : (catchError.name = [])
    e.response.data.email
      ? (catchError.email = e.response.data.email)
      : (catchError.email = [])
    e.response.data.postalCode
      ? (catchError.postalCode = e.response.data.postalCode)
      : (catchError.postalCode = [])
    e.response.data.address
      ? (catchError.address = e.response.data.address)
      : (catchError.address = [])
    e.response.data.telNumber
      ? (catchError.telNumber = e.response.data.telNumber)
      : (catchError.telNumber = [])
    e.response.data.displayCategory
      ? (catchError.displayCategory = e.response.data.displayCategory)
      : (catchError.displayCategory = [])
    e.response.data.category
      ? (catchError.category = e.response.data.category)
      : (catchError.category = [])
    e.response.data.viewPrice
      ? (catchError.viewPrice = e.response.data.viewPrice)
      : (catchError.viewPrice = [])
    e.response.data.sendPrice
      ? (catchError.sendPrice = e.response.data.sendPrice)
      : (catchError.sendPrice = [])
    e.response.data.adjustNumberStaff
      ? (catchError.adjustNumberStaff = e.response.data.adjustNumberStaff)
      : (catchError.adjustNumberStaff = [])
    e.response.data.rootUrl
      ? (catchError.rootUrl = e.response.data.rootUrl)
      : (catchError.rootUrl = [])
    setErrors(catchError)
  }

  const createCustomer = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    console.log(newCustomer)
    const formData = new FormData()

    const decNewCustomer = decamelizeKeys(newCustomer)

    for (const propaty in decNewCustomer) {
      formData.append(`${propaty}`, decNewCustomer[propaty])
    }
    uploadIcon !== null && formData.append('icon', uploadIcon, 'icon.png')

    axiosInstance
      ?.post('/api-admin/customer/', formData)
      .then((res) => {
        console.log(res)
        setShowModal(false)
        setNewCustomer(initialCustomer)
        if (customers.length >= 10) {
          customers.pop()
          setCustomers(customers)
        }
        setCustomers([res.data, ...customers])
        setCustomerSize(customerSize + 1)
        setCustomerCenter(nagano)
        setUploadIcon(null)
        setIsShowIcon(false)
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response)
          handleError(e)
        } else {
          console.error(e.message)
        }
      })
  }

  const updateCustomer = () => {
    const { axiosInstance } = ConnectAxios(() => {}, handleError)
    const formData = new FormData()

    const decNewCustomer = decamelizeKeys(newCustomer)

    for (const propaty in decNewCustomer) {
      formData.append(`${propaty}`, decNewCustomer[propaty])
    }
    uploadIcon !== null && formData.append('icon', uploadIcon, 'icon.png')

    axiosInstance
      ?.put(`/api-admin/customer/${customer_id}/`, formData)
      .then((res) => {
        console.log(res.data)
        setShowModal(false)
        setNewCustomer(initialCustomer)
        setUploadIcon(null)
        setCustomers(
          customers.map((customer) =>
            customer.id === customer_id ? res.data : customer,
          ),
        )
        setIsEdit(false)
        setIsShowIcon(false)
      })
      .catch((e) => {
        if (e.response) {
          handleError(e)
        } else {
          console.error(e.message)
        }
      })
  }

  return (
    <div>
      <AdminHeader title="顧客管理" />
      {/* <br/>
      <SearchWindow/> */}
      <Button variant="primary" onClick={handleShow} style={{ margin: 10 }}>
        追加
      </Button>
      <ChangeCategoryRadio
        handleCustomerCategory={handleCustomerCategory}
        customerCategory={customerCategory}
      />
      <SearchWindow
        margin={10}
        queryString={queryString}
        handleQueryString={handleQueryString}
        searchCustomers={searchCustomers}
      />
      <div>
        {isLoading ? (
          <ClipLoader />
        ) : (
          <Costomers
            customers={customers}
            customerSize={customerSize}
            customerPerPage={customerPerPage}
            handleGetCustomer={handleGetCustomer}
            handleEditModalShow={handleEditModalShow}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      <div>
        <Switch>
          <Route path="/admin/EditCustomer/:name">
            <EditCustomerDetail />
          </Route>
        </Switch>
      </div>

      <EditCustomerModal
        errors={errors}
        createCustomer={createCustomer}
        handleClose={handleClose}
        showModal={showModal}
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        isEdit={isEdit}
        updateCustomer={updateCustomer}
        customerCenter={customerCenter}
        setCustomerCenter={setCustomerCenter}
        mapShow={mapShow}
        handleMapClose={handleMapClose}
        handleMapShow={handleMapShow}
        initialCenter={initialCenter}
        uploadIcon={uploadIcon}
        setUploadIcon={setUploadIcon}
        iconUrl={iconUrl}
        isShowIcon={isShowIcon}
        setIsShowIcon={setIsShowIcon}
      />
    </div>
  )
}

export default EditCustomer
