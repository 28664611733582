import { Button, Modal } from 'react-bootstrap'
import { memo } from 'react'
import { modalTypes } from 'types/flyerInvoice'

const InvoiceModal: React.FC<modalTypes> = memo((props: modalTypes) => {
  const {
    isShowModal,
    handleCloseModal,
    handleFunction,
    title,
    content,
    buttonName,
  } = props

  return (
    <div>
      <Modal show={isShowModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>{content}してもいいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            閉じる
          </Button>
          <Button variant="primary" onClick={handleFunction}>
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
})

InvoiceModal.displayName = 'InvoiceModal'

export default InvoiceModal
