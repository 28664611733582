import React, { createContext, useContext, useState } from 'react'
import { authUserTypes, operationTypes } from '../types/auth'
import ConnectAxios from 'utils/connectAxios'
import { loginTypes, verifyTypes, refreshTypes } from 'types/auth'
import { AxiosInstance } from 'axios'

const initAuthUser = {
  userId: '',
  userName: '',
  customerId: '',
  customerName: '',
  displayName: '',
  loginToken: '',
  refreshToken: '',
  isEditor: false,
  isCustomeAdmin: false,
  isAdmin: false,
}

const AuthUserContext = createContext<authUserTypes | null>(initAuthUser)

const AuthOperationContext = createContext<operationTypes>({
  login: (_) => console.error('Providerが設定されていません'),
  logout: () => console.error('Providerが設定されていません'),
  verify: () => console.error('Providerが設定されていません'),
  isVerified: false,
})

const AuthUserProvider: React.FC = ({ children }) => {
  const [authUser, setAuthUser] = useState<authUserTypes | null>(
    getAuthUserOnLocalStrage(),
  )

  const [isVerified, setVerified] = useState<boolean>(false)

  const login = (
    loginVal: loginTypes,
    handleSetLoginError: any,
    isAdminLogin: boolean,
  ) => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )

    axiosInstance
      ?.post('/api-admin/auth/jwt/create', loginVal)
      .then((res) => {
        const accessToken = res.data.access
        const refreshToken = res.data.refresh

        setAuthUserItemOnLocalStrage('loginToken', accessToken)
        const headers = { headers: { Authorization: `JWT ${accessToken}` } }

        axiosInstance
          ?.get('/api-admin/auth/users/me/', headers)
          .then((res) => {
            const newAuthUser: authUserTypes = {
              userId: res.data.id,
              userName: res.data.username,
              displayName: res.data.displayName,
              customerId: res.data.customer,
              customerName: res.data.customerName,
              loginToken: accessToken,
              refreshToken: refreshToken,
              isEditor: res.data.isEditor,
              isCustomeAdmin: res.data.isCustomeAdmin,
              isAdmin: res.data.isStaff,
            }

            if (isAdminLogin && !newAuthUser.isAdmin) {
              handleSetLoginError(getLoginErrorMessage('Not Permission'))
              return
            }

            console.log('logined！', newAuthUser)
            setAuthUser(newAuthUser)
            setAuthUserOnLocalStrage(newAuthUser)
          })
          .catch((e) => {
            if (e.response) {
              handleSetLoginError(getLoginErrorMessage(e.response.statusText))
            } else {
              handleSetLoginError(getLoginErrorMessage(e.message))
            }
          })
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.data) {
            handleSetLoginError(getLoginErrorMessage(e.response.statusText))
          } else {
            handleSetLoginError(getLoginErrorMessage(e.response.statusText))
          }
        } else {
          handleSetLoginError(getLoginErrorMessage(e.message))
        }
      })
  }

  const getLoginErrorMessage = (errMsg: string): string => {
    console.log(errMsg)
    if (errMsg === 'Unauthorized' || errMsg === 'Bad Request') {
      return 'ユーザー名、もしくはパスワードが不正です。'
    }

    if (errMsg === 'Not Permission') {
      return '管理機能にログインする権限がありません。'
    }

    return '通信エラーが発生しました。'
  }

  const logout = () => {
    deleteAuthUserOnLocalStrage()
    setAuthUser(null)
  }

  const verify = (verifyVal: verifyTypes) => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => {},
    )

    return axiosInstance
      ?.post('/api-admin/auth/jwt/verify', verifyVal)
      .then(() => {
        setVerified(true)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.log('verify failed')
          console.log(
            'access Token - ',
            getAuthUserItemOnLocalStrage('loginToken'),
          )
          console.log(
            'refresh Token - ',
            getAuthUserItemOnLocalStrage('refreshToken'),
          )

          // 検証結果が401の場合リフレッシュを試す
          const refreshVal: refreshTypes = {
            refresh: getAuthUserItemOnLocalStrage('refreshToken'),
          }

          return refresh(axiosInstance, refreshVal)
        } else {
          deleteAuthUserOnLocalStrage()
          setAuthUser(null)
          console.log('verify other failed', error)
          return Promise.reject(error)
        }
      })
  }

  const refresh = (axiosInstance: AxiosInstance, refreshVal: refreshTypes) => {
    console.log('refreshToken after verify')

    return axiosInstance
      ?.post('/api-admin/auth/jwt/refresh', refreshVal)
      .then((res) => {
        setVerified(true)
        const accessToken = res.data.access
        setAuthUserItemOnLocalStrage('loginToken', accessToken)
      })
      .catch((error) => {
        console.log('トークンの取得に失敗しました', error)
        deleteAuthUserOnLocalStrage()
        setAuthUser(null)
        setVerified(false)
      })
  }

  return (
    <AuthOperationContext.Provider
      value={{ login, logout, verify, isVerified }}
    >
      <AuthUserContext.Provider value={authUser}>
        {children}
      </AuthUserContext.Provider>
    </AuthOperationContext.Provider>
  )
}

export const useAuthUser = () => useContext(AuthUserContext)
export const useLogin = () => useContext(AuthOperationContext).login
export const useLogout = () => useContext(AuthOperationContext).logout
export const useVerify = () => useContext(AuthOperationContext).verify
export const useIsVerified = () => useContext(AuthOperationContext).isVerified

export const setAuthUserOnLocalStrage = (val: authUserTypes): void => {
  localStorage.setItem('authUser', JSON.stringify(val))
}

export const getAuthUserOnLocalStrage = (): authUserTypes | null => {
  const authUser = localStorage.getItem('authUser')
  let JsonAuthUser: authUserTypes | null = null
  if (authUser !== '' && authUser !== null) {
    JsonAuthUser = JSON.parse(authUser)
  }

  return JsonAuthUser
}

export const deleteAuthUserOnLocalStrage = (): void => {
  console.info('deleteAuthUserOnLocalStrage！！')
  localStorage.removeItem('authUser')
}

export const setAuthUserItemOnLocalStrage = (
  key: string,
  val: string,
): void => {
  const authUser = localStorage.getItem('authUser')

  if (authUser !== '' && authUser !== null) {
    const JsonAuthUser = JSON.parse(authUser)
    JsonAuthUser[key] = val
    localStorage.setItem('authUser', JSON.stringify(JsonAuthUser))
  }
}

export const getAuthUserItemOnLocalStrage = (key: string): string => {
  const authUser = localStorage.getItem('authUser')
  let item = ''
  if (authUser !== '' && authUser !== null) {
    const JsonAuthUser = JSON.parse(authUser)
    item = JsonAuthUser[key]
  }
  return item
}

export const isAuthenticated = (): boolean => {
  const authUser = getAuthUserOnLocalStrage()
  return authUser ? true : false
}

export const isAdminAuthenticated = (): boolean => {
  let isAuthenticated = false

  const authUser = getAuthUserOnLocalStrage()
  if (authUser && authUser.isAdmin) {
    isAuthenticated = true
  }

  return isAuthenticated
}

export default AuthUserProvider
