import { useAuthUser } from 'routes/AuthUserContext'
import AdminHeader from '../AdminHeader'
import { AxiosInstance } from 'axios'
import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import useAxios from 'hocks/axios'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { customerFlyerMonth } from 'types/customers'
import { useParams } from 'react-router'
import ConnectAxios from 'utils/connectAxios'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import DeliverDetailMap from 'components/common/DeliverDetailMap'
import { zoomCalc } from 'utils/zoomCalc'
import BackButton from '../BackButton'
import { isSearchType } from 'utils/searchCondition'
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_SEATCH_TYPE,
} from 'const/admin/SeacrhCustomer'

const CustomerSendedStatusList: React.FC = () => {
  const { customerId } = useParams<{ customerId: any }>()
  const { searchType } = useParams<{ searchType: string }>()
  const { searchWord } = useParams<{ searchWord: string }>()
  const { pageIndex } = useParams<{ pageIndex: string }>()
  const authUser = useAuthUser()
  const today = new Date()
  const initalData = { id: 0, name: '', flyers: [] }
  const [customerFlyerMonth, setCustomerFlyerMonth] =
    useState<customerFlyerMonth>(initalData)
  const initialYearMonth = Number(
    '' + today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2),
  )
  const [yearMonth, setYearMonth] = useState<number>(initialYearMonth)

  const [deliverDetailInfo, setDeliverDetailInfo] = useState({
    radius: 0,
    location: [],
  })

  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  })

  const [title, setTitle] = useState<string>('')
  const [zoom, setZoom] = useState<number | undefined>(15)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    const { axiosInstance } = useAxios(
      () => {},
      () => console.log('初回取得に失敗しました。'),
    )
    getFlyers(axiosInstance)
  }, [yearMonth])

  const getFlyers = (axiosInstance: AxiosInstance) => {
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}${customerId}/flyer/${yearMonth}/`, {})
      .then((response) => {
        setCustomerFlyerMonth(response.data)
      })
  }

  const handleYear = (event) => {
    const selectYearMonth = Number(
      '' + event.target.value.slice(0, 4) + event.target.value.slice(5, 7),
    )
    setYearMonth(selectYearMonth)
  }

  const sendStatus = {
    1: '配信済',
    2: '配信中',
    3: '配信予定',
  }

  const getTableWidth = (isCusotmeAdmin) => {
    let width
    if (isCusotmeAdmin == true) {
      width = 800
    } else {
      width = 500
    }
    return width
  }
  const tableStyle: any = {
    fontSize: 13,
    minWidth: getTableWidth(authUser?.isCustomeAdmin),
  }
  const center: React.CSSProperties = {
    textAlign: 'center',
  }
  const right: React.CSSProperties = {
    textAlign: 'right',
  }

  const getDeliverDetailInfo = (flyerId) => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance
      ?.get(`api-admin/flyer/deliver_detail/${flyerId}/`)
      .then((res) => {
        setDeliverDetailInfo({
          radius: res.data.radius,
          location: res.data.location,
        })
        setMapCenter({
          lat: res.data.lat,
          lng: res.data.lng,
        })
        setTitle(res.data.title)
        setZoom(zoomCalc(res.data.radius))
        console.log(res.data)
      })
      .then(() => {
        handleShow()
      })
      .catch((e) => {
        console.log(e.response)
      })
  }

  const urlPath = window.location.pathname.split('/')

  return (
    <div>
      <AdminHeader title={`配信状況 - ${customerFlyerMonth.name}`} />
      <input
        style={{ margin: 20 }}
        type="month"
        defaultValue={
          today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2)
        }
        onChange={() => handleYear(event)}
      />
      <BackButton
        title={urlPath[2]}
        searchType={isSearchType(searchType) ? searchType : DEFAULT_SEATCH_TYPE}
        searchWord={searchWord}
        pageIndex={Number(pageIndex) ?? DEFAULT_PAGE_INDEX}
      />
      <br />
      <Table responsive="sm" style={tableStyle}>
        <thead>
          <tr style={center}>
            <th>タイトル</th>
            <th>配信期間</th>
            <th>配信状況</th>
            <th>配信数</th>
            <th>閲覧数</th>
          </tr>
        </thead>
        <tbody>
          {customerFlyerMonth.flyers.map((flyer, flyerIdx) => (
            <tr key={flyerIdx}>
              <td>
                <p>
                  {flyer.title.length > 20
                    ? `${flyer.title.slice(0, 20)}...`
                    : flyer.title}
                </p>
              </td>
              <td style={center}>
                {flyer.fromDatetime} 〜 {flyer.toDatetime}
              </td>

              <td style={center}>
                {flyer.sendStatus in sendStatus && (
                  <Link
                    to="#"
                    onClick={() => {
                      getDeliverDetailInfo(flyer.id)
                    }}
                  >
                    {sendStatus[flyer.sendStatus]}
                  </Link>
                )}
              </td>
              <td style={right}>{flyer.adjustedSendCount}</td>
              <td style={right}>{flyer.adjustedViewCount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            配信状況
            <br />
            タイトル： {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeliverDetailMap
            radius={deliverDetailInfo.radius}
            center={mapCenter}
            locations={deliverDetailInfo.location}
            zoom={zoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CustomerSendedStatusList
