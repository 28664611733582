import ConnectAxios from 'utils/connectAxios'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

const useAxios = (
  successHookCallback?: (response: AxiosResponse) => void,
  failedHookCallback?: (error: AxiosError) => void
): {
  axiosInstance: AxiosInstance
  isLoading?: boolean
} => {
  return ConnectAxios(successHookCallback, failedHookCallback)
}

export default useAxios
