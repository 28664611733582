import { Alert } from 'react-bootstrap'

import { PasswordResetErrorTypes } from 'types/passwordReset'

const PasswordResetError: React.FC<PasswordResetErrorTypes> = (
  props: PasswordResetErrorTypes,
) => {
  const { errors } = props

  return (
    <div>
      {errors.map((error) => {
        return (
          <Alert key={error} variant="danger">
            {error}
          </Alert>
        )
      })}
    </div>
  )
}

export default PasswordResetError
