import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useLogin } from 'routes/AuthUserContext'
import { loginTypes } from 'types/auth'
import LoginError from './LoginError'

const LoginAdmin: React.FC = () => {
  const initVal: loginTypes = {
    username: '',
    password: '',
  }

  const [loginVal, setLoginVal] = useState(initVal)
  const [loginError, setLoginError] = useState(null)

  const login = useLogin()

  const handleLogin = () => {
    login(loginVal, setLoginError, true)
  }

  const inputChange = (evt) => {
    const key = evt.target.name
    const value = evt.target.value
    loginVal[key] = value
    const data = Object.assign({}, loginVal)
    setLoginVal(data)
  }

  return (
    <div>
      <h2>管理者向け ログイン</h2>
      <Form>
        <Form.Group className="mb-3" controlId="formUserName">
          <Form.Label>ユーザー名</Form.Label>
          <Form.Control
            name="username"
            type="text"
            value={loginVal.username}
            onChange={(e) => inputChange(e)}
            placeholder="ユーザー名を入力してください"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>パスワード</Form.Label>
          <Form.Control
            name="password"
            type="password"
            value={loginVal.password}
            onChange={(e) => inputChange(e)}
            placeholder="パスワードを入力してください"
          />
          <br />
          <LoginError errorMsg={loginError} />
        </Form.Group>
        <Button variant="primary" onClick={handleLogin}>
          送信
        </Button>
      </Form>
    </div>
  )
}

export default LoginAdmin
