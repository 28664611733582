import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthUser } from 'routes/AuthUserContext'
import ConnectAxios from 'utils/connectAxios'
import { invoiceDownload } from 'utils/invoiceDownload'

const CustomerInvoiceDownload: React.FC = () => {
  const authUser = useAuthUser()
  const { yearMonth } = useParams<{ yearMonth: any }>()
  const customerId = authUser ? authUser.customerId : ''
  const customerName = authUser ? authUser.customerName : ''
  const [isInvoiceDownload, setIsInvoiceDownload] = useState<boolean>(false)
  const isAdmin = false

  useEffect(() => {
    invoiceDownloadDate()
    invoiceDownload(customerId, customerName, yearMonth, isAdmin)
  }, [])

  const invoiceDownloadDate = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance
      ?.patch(
        `${ADMIN_URL_CUSTOMER}${customerId}/invoice/${yearMonth}/download/`,
        {},
      )
      .then(() => {
        setIsInvoiceDownload(true)
      })
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <p>{authUser?.customerName} 様</p>
      <p>
        {yearMonth.slice(0, 4)}年{yearMonth.slice(4, 6)}月分 請求書
      </p>

      {isInvoiceDownload ? <p>ダウンロード完了</p> : <p>ダウンロード中…</p>}
    </div>
  )
}

export default CustomerInvoiceDownload
