import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { flyerTypes } from 'types/flyerInvoice'
import useAxios from 'hocks/axios'
import { AxiosInstance } from 'axios'
import AdminHeader from '../AdminHeader'
import FlyerTable from './FlyerTable'
import BackButton from '../BackButton'
import { isSearchType } from 'utils/searchCondition'
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_SEATCH_TYPE,
} from 'const/admin/SeacrhCustomer'

const FlyerList: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>()
  const { searchType } = useParams<{ searchType: string }>()
  const { searchWord } = useParams<{ searchWord: string }>()
  const { pageIndex } = useParams<{ pageIndex: string }>()
  const now = new Date()
  const beginningLastMonth = new Date(now.getFullYear(), now.getMonth() - 1)
  const lastMonth = Number(
    String(beginningLastMonth.getFullYear()) +
      '0' +
      String(beginningLastMonth.getMonth() + 1),
  )

  const [thisMonth, setThisMonth] = useState<flyerTypes[]>([])
  const [beforeMonth, setBeforeMonth] = useState<flyerTypes[]>([])
  const [isInvoiceConfirm, setIsInvoiceConfirm] = useState<boolean>(false)
  const [customerName, setCustomerName] = useState<string>('')

  useEffect(() => {
    const { axiosInstance } = useAxios(
      () => {},
      () => console.log('初回取得に失敗しました。'),
    )
    getFlyerInvoices(axiosInstance)
  }, [])

  const getFlyerInvoices = (axiosInstance: AxiosInstance) => {
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}${customerId}/flyer/`, {})
      .then((response) => {
        setCustomerName(response.data.name)
        setThisMonth(response.data.flyers.thisMonth)
        if (response.data.flyers.beforeMonth.length) {
          setBeforeMonth(response.data.flyers.beforeMonth)
          const confirmDateYearMonth =
            response.data.flyers.beforeMonth[0].confirmDateYearMonth
          if (confirmDateYearMonth == lastMonth) {
            setIsInvoiceConfirm(true)
          }
        }
      })
  }

  const urlPath = window.location.pathname.split('/')

  return (
    <div>
      <AdminHeader title="請求書管理" />
      <br />
      <BackButton
        title={urlPath[2]}
        searchType={isSearchType(searchType) ? searchType : DEFAULT_SEATCH_TYPE}
        searchWord={searchWord}
        pageIndex={Number(pageIndex) ?? DEFAULT_PAGE_INDEX}
      />
      <FlyerTable
        flyers={thisMonth}
        setFlyers={setThisMonth}
        isUpdate={true}
        isInvoiceConfirm={isInvoiceConfirm}
        setIsInvoiceConfirm={setIsInvoiceConfirm}
        customerId={customerId}
        customerName={customerName}
        lastMonth={lastMonth}
      />
      <FlyerTable
        flyers={beforeMonth}
        setFlyers={setBeforeMonth}
        isUpdate={false}
        isInvoiceConfirm={isInvoiceConfirm}
        setIsInvoiceConfirm={setIsInvoiceConfirm}
        customerId={customerId}
        customerName={customerName}
        lastMonth={lastMonth}
      />
    </div>
  )
}

export default FlyerList
