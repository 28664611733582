import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import ConnectAxios from './connectAxios'

export const invoiceDownload = (
  customerId: string,
  customerName: string,
  yearMonth: number,
  isAdmin: boolean,
) => {
  const { axiosInstance } = ConnectAxios(
    () => {},
    () => console.log('通信に失敗しました。'),
  )
  const downloadGetUrl = isAdmin
    ? `${ADMIN_URL_CUSTOMER}${customerId}/invoice/${yearMonth}/`
    : `${ADMIN_URL_CUSTOMER}${customerId}/invoice/${yearMonth}/download/`
  axiosInstance
    ?.get(downloadGetUrl, {
      responseType: 'blob',
    })
    .then((res) => {
      const fileName = `${customerName}様_${yearMonth}_御請求書.pdf`
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const blobUrl = (window.URL || window.webkitURL).createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
}
