import { useAuthUser } from 'routes/AuthUserContext'
import CustomerHeader from '../CustomerHeader'
import { AxiosInstance } from 'axios'
import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import useAxios from 'hocks/axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { customerFlyerMonth } from 'types/customers'
import { Button } from 'react-bootstrap'
import CustomerFlyerTable from './CustomerFlyerTable'

const CustomerFlyer: React.FC = () => {
  const authUser = useAuthUser()
  const customerId = authUser ? authUser.customerId : ''
  const today = new Date()
  const [total, setTotal] = useState<number>(0)
  const initalData = { id: 0, name: '', flyers: [] }
  const [customerFlyerMonth, setCustomerFlyerMonth] =
    useState<customerFlyerMonth>(initalData)
  const initialYearMonth = Number(
    '' + today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2),
  )
  const [yearMonth, setYearMonth] = useState<number>(initialYearMonth)

  useEffect(() => {
    const { axiosInstance } = useAxios(
      () => {},
      () => console.log('取得に失敗しました。'),
    )
    getFlyers(axiosInstance)
  }, [yearMonth])

  const getFlyers = (axiosInstance: AxiosInstance) => {
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}${customerId}/flyer/${yearMonth}/`, {})
      .then((response) => {
        console.log('getFlyers', response)
        setCustomerFlyerMonth(response.data)
      })
  }

  const handleYear = (event) => {
    const selectYearMonth = Number(
      '' + event.target.value.slice(0, 4) + event.target.value.slice(5, 7),
    )
    setYearMonth(selectYearMonth)
  }

  const decimalPlaces = 10
  const subTotalCalculate = (
    adjustedSendCount: number,
    sendPrice: number,
    adjustedViewCount: number,
    viewPrice: number,
  ) => {
    const subTotal =
      Math.floor(
        (adjustedSendCount * sendPrice + adjustedViewCount * viewPrice) *
          decimalPlaces,
      ) / decimalPlaces
    return subTotal
  }
  useEffect(() => {
    const totalArr: number[] = []
    customerFlyerMonth.flyers.map((flyer) => {
      flyer.subTotal = subTotalCalculate(
        flyer.adjustedSendCount,
        flyer.sendPrice,
        flyer.adjustedViewCount,
        flyer.viewPrice,
      )
      totalArr.push(flyer.subTotal)
    })
    const flyerReducer = (prevValue, currentValue) => prevValue + currentValue
    setTotal(
      Math.floor(
        (totalArr.reduce(flyerReducer, 0) * decimalPlaces) / decimalPlaces,
      ),
    )
  }, [customerFlyerMonth])

  return (
    <div>
      <CustomerHeader
        title={`情報管理 ： ${authUser ? authUser.customerName : ''}`}
      />
      <input
        style={{ margin: 20 }}
        type="month"
        defaultValue={
          today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2)
        }
        onChange={() => handleYear(event)}
      />
      <br />
      <Link to={'/customer/editFlyer'}>
        <Button
          variant="primary"
          size="sm"
          style={{ marginLeft: 20, marginBottom: 20 }}
        >
          新規作成
        </Button>
      </Link>
      <br />
      <CustomerFlyerTable
        customerFlyerMonth={customerFlyerMonth}
        total={total}
      />
    </div>
  )
}
export default CustomerFlyer
