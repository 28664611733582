const clickedMapView: React.FC = () => {
  return (
    <div>
      <h2>配信状況</h2>
      <hr />
      <h3>チラシを受け取ったユーザー情報が載るGoogleMap</h3>
    </div>
  )
}

export default clickedMapView
