import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ADMIN_URL_CUSTOMER } from 'definitions/const'
import { AxiosInstance } from 'axios'
import AdminHeader from '../AdminHeader'
import Pagination from 'utils/Pagination'
import { filterTypes, invoiceTypes, statusTypes } from 'types/invoice'
import ConnectAxios from 'utils/connectAxios'
import InvoiceModal from './InvoiceModal'
import useAxios from 'hocks/axios'
import InvoiceTable from './invoiceTable'

const BatchProcess: React.FC = () => {
  const now = new Date()
  const beginningLastMonth = new Date(now.getFullYear(), now.getMonth() - 1)
  const initialYearMonth = Number(
    '' +
      beginningLastMonth.getFullYear() +
      ('0' + (beginningLastMonth.getMonth() + 1)).slice(-2),
  )
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowMailModal, setIsShowMailModal] = useState<boolean>(false)
  const [isShowCheckDateModal, setIsShowCheckDateModal] =
    useState<boolean>(false)
  const initialFilter = {
    nullOnlyDownloadAt: false,
    nullOnlyPaymentCheckDate: false,
  }
  const [page, setPage] = useState<number>(1)
  const [isFilteredColumns, isSetFilteredColumns] =
    useState<filterTypes>(initialFilter)
  const [updateInvoiceId, setUpdateInvoiceId] = useState<number | null>(null)
  const [yearMonth, setYearMonth] = useState<number>(initialYearMonth)
  const [invoices, setInvoices] = useState<invoiceTypes[]>([])
  const [invoiceSize, setInvoiceSize] = useState(1)
  const [invoicePerPage, setInvoicePerPage] = useState(1)
  const status = {
    notYet: 0,
    inProgress: 1,
    completed: 2,
  }
  const [batchProcessStatus, setBatchProcessStatus] = useState<statusTypes>({
    invoice: status.notYet,
    mail: status.notYet,
  })
  // 検索時にページネーションのフォーカスを1にするためのstate
  const [currentPage, setCurrentPage] = useState<number>(0)

  const handleYear = (event) => {
    const selectYearMonth = Number(
      '' + event.target.value.slice(0, 4) + event.target.value.slice(5, 7),
    )
    setYearMonth(selectYearMonth)
  }

  useEffect(() => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('取得に失敗しました。'),
    )
    getInvoices(axiosInstance, page)
  }, [yearMonth, isFilteredColumns])

  const { axiosInstance } = useAxios(
    () => {},
    () => console.log('取得に失敗しました。'),
  )
  const getInvoices = (axiosInstance: AxiosInstance, page: number) => {
    axiosInstance
      ?.get(`${ADMIN_URL_CUSTOMER}invoice/${yearMonth}/?page=${page}`, {
        params: {
          null_only_download_at: isFilteredColumns.nullOnlyDownloadAt,
          null_only_payment_check_date:
            isFilteredColumns.nullOnlyPaymentCheckDate,
        },
      })
      .then((response) => {
        setInvoices(response.data.results)
        setInvoiceSize(response.data.count)
        setInvoicePerPage(response.data.perPage)
        setBatchProcessStatus({
          ...batchProcessStatus,
          invoice: response.data.results[0].invoiceStatus,
          mail: response.data.results[0].mailStatus,
        })
      })
      .catch(() => {
        setInvoices([])
        setInvoiceSize(1)
        setInvoicePerPage(1)
        setBatchProcessStatus({ invoice: status.notYet, mail: status.notYet })
      })
  }
  const handleGetInvoices = (page: number) => {
    getInvoices(axiosInstance, page)
    setPage(page)
  }

  const invoicesBactchProcess = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance
      ?.post(`${ADMIN_URL_CUSTOMER}invoice/batch_process/${yearMonth}/`, {})
      .then((response) => {
        console.log(response.data)
        setBatchProcessStatus({
          ...batchProcessStatus,
          invoice: response.data.invoiceStatus,
        })
      })
  }
  const sendMail = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance
      ?.post(`${ADMIN_URL_CUSTOMER}invoice/send_mail/${yearMonth}/`, {})
      .then((response) => {
        setBatchProcessStatus({
          ...batchProcessStatus,
          mail: response.data.mailStatus,
        })
      })
  }
  const paymentCheckDateUpdate = () => {
    const { axiosInstance } = ConnectAxios(
      () => {},
      () => console.log('通信に失敗しました。'),
    )
    axiosInstance
      ?.patch(`${ADMIN_URL_CUSTOMER}invoice/patch/${updateInvoiceId}/`, {})
      .then((response) => {
        const updateInvoices = invoices.map((invoice) => {
          if (invoice.id === response.data.id) {
            return response.data
          } else {
            return invoice
          }
        })
        setInvoices(updateInvoices)
      })
  }
  /* eslint-disable */
  const handleFilter = (column: string) => {
    if (column == 'downloadAt') {
      isFilteredColumns.nullOnlyDownloadAt
        ? isSetFilteredColumns({
            ...isFilteredColumns,
            nullOnlyDownloadAt: false,
          })
        : isSetFilteredColumns({
            ...isFilteredColumns,
            nullOnlyDownloadAt: true,
          })
    } else {
      isFilteredColumns.nullOnlyPaymentCheckDate
        ? isSetFilteredColumns({
            ...isFilteredColumns,
            nullOnlyPaymentCheckDate: false,
          })
        : isSetFilteredColumns({
            ...isFilteredColumns,
            nullOnlyPaymentCheckDate: true,
          })
    }
  }
  /* eslint-disable */

  const handleCloseModal = (modal: string) => {
    modal == 'confirm' && setIsShowConfirmModal(false)
    modal == 'mail' && setIsShowMailModal(false)
    modal == 'checkDate' && setIsShowCheckDateModal(false)
  }
  const handleShowModal = (modal: string) => {
    modal == 'confirm' && setIsShowConfirmModal(true)
    modal == 'mail' && setIsShowMailModal(true)
  }
  const handleCheckDateShowModal = (invoiceId: number) => {
    setIsShowCheckDateModal(true)
    setUpdateInvoiceId(invoiceId)
  }
  const handleConfirm = () => {
    setIsShowConfirmModal(false)
    invoicesBactchProcess()
  }
  const handleSendMail = () => {
    setIsShowMailModal(false)
    sendMail()
  }
  const handlePaymentCheckDate = () => {
    setIsShowCheckDateModal(false)
    paymentCheckDateUpdate()
  }

  const handleReload = () => {
    location.reload()
  }

  return (
    <div>
      <InvoiceModal
        isShowModal={isShowConfirmModal}
        handleCloseModal={() => handleCloseModal('confirm')}
        handleFunction={() => handleConfirm()}
        title="確定確認"
        content="請求書を一括確定"
        buttonName="確定"
      ></InvoiceModal>
      <InvoiceModal
        isShowModal={isShowMailModal}
        handleCloseModal={() => handleCloseModal('mail')}
        handleFunction={() => handleSendMail()}
        title="送信確認"
        content="メールを一括送信"
        buttonName="送信"
      ></InvoiceModal>
      <InvoiceModal
        isShowModal={isShowCheckDateModal}
        handleCloseModal={() => handleCloseModal('checkDate')}
        handleFunction={() => handlePaymentCheckDate()}
        title="入金確認"
        content="入金確認を実行"
        buttonName="実行"
      ></InvoiceModal>

      <AdminHeader title="請求情報　-　一括確定処理" />
      <input
        style={{ margin: 20 }}
        type="month"
        defaultValue={
          beginningLastMonth.getFullYear() +
          '-' +
          ('0' + (beginningLastMonth.getMonth() + 1)).slice(-2)
        }
        onChange={() => handleYear(event)}
      />
      <br />
      {batchProcessStatus.invoice == status.notYet &&
        /* eslint-disable */
        initialYearMonth >= yearMonth && (
          <div>
            <Button
              variant="primary"
              size="sm"
              style={{ marginLeft: 20, marginBottom: 20 }}
              onClick={() => handleShowModal('confirm')}
            >
              請求書一括確定
            </Button>
          </div>
          /* eslint-disable */
        )}
      {batchProcessStatus.invoice == status.inProgress && (
        <div style={{ marginLeft: 20, marginBottom: 20 }}>
          <p>請求書一括処理中...</p>
          <Button variant="primary" size="sm" onClick={handleReload}>
            ページ再読み込み
          </Button>
        </div>
      )}
      {batchProcessStatus.mail == status.inProgress && (
        <div style={{ marginLeft: 20, marginBottom: 20 }}>
          <p>メール一括送信中...</p>
          <Button variant="primary" size="sm" onClick={handleReload}>
            ページ再読み込み
          </Button>
        </div>
      )}
      {batchProcessStatus.invoice == status.completed &&
        batchProcessStatus.mail == status.notYet && (
          /* eslint-disable */
          <div>
            <Button
              variant="primary"
              size="sm"
              style={{ marginLeft: 20, marginBottom: 20 }}
              onClick={() => handleShowModal('mail')}
            >
              メール一括送信
            </Button>
          </div>
          /* eslint-disable */
        )}
      {batchProcessStatus.mail == status.completed && (
        <div>
          <Button
            variant="primary"
            size="sm"
            style={{ marginLeft: 20, marginBottom: 20 }}
            disabled
          >
            メール一括送信済み
          </Button>
        </div>
      )}
      <br />
      <InvoiceTable
        isFilteredColumns={isFilteredColumns}
        invoices={invoices}
        yearMonth={yearMonth}
        handleFilter={handleFilter}
        handleCheckDateShowModal={handleCheckDateShowModal}
      />

      {invoiceSize > 0 && (
        <Pagination
          dataSize={invoiceSize}
          perPage={invoicePerPage}
          handleGetData={handleGetInvoices}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  )
}
export default BatchProcess
