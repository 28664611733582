import React, { useMemo } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import './style/quill-styles.css'

import { getBaseUrl } from 'utils/baseAxios'
import { ckeditorTypes, imageUploadResponse } from 'types/flyer'
import { getAuthUserItemOnLocalStrage } from 'routes/AuthUserContext'
import loadImage, { MetaData, LoadImageOptions } from 'blueimp-load-image'

class ImageUploadAdapter {
  uploadFile: any
  xhr: any
  baseUrl: string
  staffId: number | any
  setImage: any

  constructor(uploadFile, baseUrl, staffId, setImage) {
    this.uploadFile = uploadFile
    this.xhr = null
    this.baseUrl = baseUrl
    this.staffId = staffId
    this.setImage = setImage
  }

  upload = () => {
    return new Promise<imageUploadResponse>((resolve, reject) => {
      this._initRequest(this.setImage)
      this._initListenersAndRequest(resolve, reject, this.uploadFile)
    })
  }

  abort = () => {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  getApiToken = (): string | null => {
    const token = getAuthUserItemOnLocalStrage('loginToken')
    return token
  }

  _initRequest = (setImage) => {
    const xhr = (this.xhr = new XMLHttpRequest())

    const DONE = 4

    xhr.onreadystatechange = function () {
      if (this.readyState == DONE && this.status == 200) {
        setImage((pre) => [...pre, this.response.id])
      }
    }

    xhr.open(
      'POST',
      `${this.baseUrl}api-admin/flyer/image/upload/${this.staffId}/`,
      true,
    )
    xhr.responseType = 'json'
    // xhr.setRequestHeader('content-type', 'application/json')
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.setRequestHeader('Authorization', `JWT ${this.getApiToken()}`)
  }

  _initListenersAndRequest = (resolve, reject, file) => {
    const xhr = this.xhr
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      if (!response || response.error) {
        console.error(`Image upload Error:${response.error}`)
        return reject(
          response && response.error
            ? response.error.message
            : genericErrorText,
        )
      }
      const res: imageUploadResponse = {
        url: response.url,
        imageId: response.id,
      }

      resolve(res)
    })

    // https://gist.github.com/kenmori/fe204e0970c2d77b81ea0b857a2303b3
    loadImage.parseMetaData(file, (data: MetaData) => {
      const options: LoadImageOptions = {
        canvas: true,
      }
      if (data.exif) {
        options.orientation = (
          data.exif as {
            get: (id: string) => number
          }
        ).get('Orientation')
      }

      loadImage(
        file,
        (canvas: Event | HTMLCanvasElement | HTMLImageElement) => {
          const dataUri = (canvas as HTMLCanvasElement).toDataURL('image/jpeg')
          const byteString = atob(dataUri.split(',')[1])
          const target = dataUri.match(/(:)([a-z]+)(;)/)
          const mimeType = target ? target[2] : 'image/jpeg'
          const content = new Uint8Array(byteString.length)

          for (let i = 0; byteString.length > i; i++) {
            content[i] = byteString.charCodeAt(i)
          }

          const blob = new Blob([content], {
            type: mimeType,
          })
          _sendRequest(blob, file.name)
        },
        options,
      )

      const _sendRequest = (file, fileName) => {
        const data = new FormData()

        data.append('image', file, fileName)

        xhr.send(data)
      }
    })
  }
}

let quillObj: any

const CustomQuill: React.FC<ckeditorTypes> = (props: ckeditorTypes) => {
  const {
    staffId,
    content,
    handleSetImageId,
    handleSetContent,
    isInitial,
    setImage,
  } = props

  const imageHandler = () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    document.body.appendChild(input)

    input.onchange = async () => {
      const baseUrl = getBaseUrl()
      const file = input.files !== null ? input.files[0] : null
      const imageUploader = new ImageUploadAdapter(
        file,
        baseUrl,
        staffId,
        setImage,
      )

      imageUploader.upload().then((data: imageUploadResponse) => {
        console.log(isInitial)
        if (handleSetImageId) handleSetImageId(String(data.imageId))
        const range = quillObj.getEditorSelection()
        quillObj.getEditor().insertEmbed(range.index, 'image', data.url)
      })
      document.body.removeChild(input)
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['image', 'link'],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', { color: [] }, { background: [] }, 'italic'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  )

  return (
    <div>
      <ReactQuill
        theme="snow"
        ref={(el) => {
          quillObj = el
          console.log(quillObj)
        }}
        value={content}
        modules={modules}
        onChange={(_content, _delta, _source, editor) => {
          if (isInitial !== true) {
            if (handleSetContent) handleSetContent(editor.getHTML())
          }
        }}
      />
    </div>
  )
}

export default CustomQuill
