import { Alert } from 'react-bootstrap'
import { editCustomerError } from 'types/customers'

const EditCustomerError: React.FC<editCustomerError> = (
  props: editCustomerError,
) => {
  const { errors } = props

  return (
    <div>
      {errors.map((e) => {
        return (
          <Alert key={e} variant="danger">
            {e}
          </Alert>
        )
      })}
    </div>
  )
}

export default EditCustomerError
