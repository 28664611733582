import React from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { useAuthUser } from 'routes/AuthUserContext'
import { Link } from 'react-router-dom'
import { customerFlyerTableTypes } from 'types/customers'

const sendStatus = {
  1: '配信済',
  2: '配信中',
  3: '配信予定',
}

const CustomerFlyerTable: React.FC<customerFlyerTableTypes> = (
  props: customerFlyerTableTypes,
) => {
  const authUser = useAuthUser()

  const getTableWidth = (isCusotmeAdmin) => {
    let width
    if (isCusotmeAdmin == true) {
      width = 800
    } else {
      width = 500
    }
    return width
  }

  const tableStyle: React.CSSProperties = {
    fontSize: 13,
    minWidth: getTableWidth(authUser?.isCustomeAdmin),
  }
  const center: React.CSSProperties = {
    textAlign: 'center',
  }
  const right: React.CSSProperties = {
    textAlign: 'right',
  }

  const loadingContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
  }
  const loadingStyle: React.CSSProperties = {
    marginLeft: '0.5rem',
  }
  if (!props.customerFlyerMonth.id) {
    return (
      <div style={loadingContainerStyle}>
        <Spinner animation="border" />
        <div style={loadingStyle}>情報取得中...</div>
      </div>
    )
  }

  return (
    <Table responsive="sm" style={tableStyle}>
      <thead>
        <tr style={center}>
          <th>タイトル</th>
          <th>配信日</th>
          <th>配信状況</th>
          {authUser?.isCustomeAdmin && <th>配信数</th>}
          {authUser?.isCustomeAdmin && <th>配信単価</th>}
          {authUser?.isCustomeAdmin && <th>閲覧数</th>}
          {authUser?.isCustomeAdmin && <th>閲覧単価</th>}
          {authUser?.isCustomeAdmin && <th>御利用額 (税抜)</th>}
        </tr>
      </thead>
      <tbody>
        {props.customerFlyerMonth.flyers.map((flyer, flyerIdx) => (
          <tr key={flyerIdx}>
            <td>
              <Link to={`/customer/editFlyer/${flyer.id}`}>
                {flyer.title.length > 20
                  ? `${flyer.title.slice(0, 20)}...`
                  : flyer.title}
              </Link>
            </td>
            <td style={center}>{flyer.fromDate}</td>
            {flyer.sendStatus in sendStatus && (
              <td style={center}>{sendStatus[flyer.sendStatus]}</td>
            )}
            {authUser?.isCustomeAdmin && (
              <td style={right}>{flyer.adjustedSendCount}</td>
            )}
            {authUser?.isCustomeAdmin && (
              <td style={right}>{flyer.sendPrice}</td>
            )}
            {authUser?.isCustomeAdmin && (
              <td style={right}>{flyer.adjustedViewCount}</td>
            )}
            {authUser?.isCustomeAdmin && (
              <td style={right}>{flyer.viewPrice}</td>
            )}
            {authUser?.isCustomeAdmin && (
              <td style={right}>{flyer.subTotal}</td>
            )}
          </tr>
        ))}
      </tbody>
      {authUser?.isCustomeAdmin && (
        <tfoot>
          <tr style={{ fontSize: 14, fontWeight: 'bold' }}>
            <td colSpan={7}>御利用額 合計</td>
            <td style={right}>{props.total}</td>
          </tr>
        </tfoot>
      )}
    </Table>
  )
}

export default CustomerFlyerTable
