import axios, { AxiosRequestConfig } from 'axios'
import { decamelizeKeys } from 'humps'
import { getAuthUserItemOnLocalStrage } from 'routes/AuthUserContext'

const createBaseAxios = () => {
  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
  })

  axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const newRequest = { ...request }

    if (!newRequest.headers['Authorization']) {
      newRequest.headers['Authorization'] = `JWT ${getApiToken()}`
    }

    if (!newRequest.headers['content-type']) {
      newRequest.headers['content-type'] = 'application/json'
    }

    if (!newRequest.responseType) {
      newRequest.responseType = 'json'
    }

    console.info('axios request', request)

    // multipart/form-dataの場合は、上位層でdecamelizeすること。
    if (!(request.data instanceof FormData) && request.data) {
      newRequest.data = decamelizeKeys(request.data)
    }

    return newRequest
  })

  return axiosInstance
}

export const getBaseUrl = (): string => {
  const baseUrl = process.env.REACT_APP_API_URL
  return `${baseUrl}/relesta-api/`
}

export const getApiToken = (): string | null => {
  const token = getAuthUserItemOnLocalStrage('loginToken')
  return token
}

const BaseAxios = createBaseAxios()
export default BaseAxios
